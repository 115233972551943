import { NetworkStatus, NetworkStrength, NetworkType } from "@elx-element/common/enums";
import { MachineSettings } from "@elx-element/common/storage/types";

import * as ActionTypes from "./types";

/**
 * Akce pro nastavení vybrané kultury
 * @param culture
 */
export function setCulture(culture: string): ActionTypes.setCulture {
  return {
    type: ActionTypes.SET_CULTURE,
    payload: culture,
  };
}

/**
 * Akce pro nastavení stavu aplikace online / offline
 * @param culture
 */
export function setNetworkStatus(status: NetworkStatus): ActionTypes.setNetworkStatus {
  return {
    type: ActionTypes.SET_NETWORK_STATUS,
    payload: status,
  };
}

export function setOfflineMode(baseModulePath?: string): ActionTypes.setOfflineMode {
  return {
    type: ActionTypes.SET_OFFLINE_MODE,
    payload: window.inCapacitor ? baseModulePath : undefined,
  };
}

export function setNetworkType(status: NetworkType): ActionTypes.setNetworkType {
  return {
    type: ActionTypes.SET_NETWORK_TYPE,
    payload: status,
  };
}

export function setNetworkStrength(status: NetworkStrength): ActionTypes.setNetworkStrength {
  return {
    type: ActionTypes.SET_NETWORK_STRENGTH,
    payload: status,
  };
}

export function setMachineSettings(settings: MachineSettings): ActionTypes.setMachineSettings {
  return {
    type: ActionTypes.SET_MACHINE_SETTINGS,
    payload: settings,
  };
}

export function setInfoPopupOpen(isOpen: boolean): ActionTypes.setInfoPopupOpen {
  return {
    type: ActionTypes.SET_INFO_POPUP_OPEN,
    payload: isOpen,
  };
}
