/* eslint-disable no-param-reassign */
import { Dispatch, Fragment, useEffect, useState } from "react";

import { ZebraScanResult } from "capacitor-elinkx-zebra-scanner/src/definitions";
import { batch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { apiMan } from "@elx-element/common/apiClient/apiMan";
import {
  CommonErrors,
  ElementInformationType,
  EventCode,
  ModuleInterfaceDirectionFlag,
  NetworkStatus,
  Notification,
  ScannerOption,
  ScanType,
  WebContainerPlatform,
} from "@elx-element/common/enums";
import {
  getConfigurationBoolValue,
  getConfigurationNumberValue,
  getConfigurationStringValue,
} from "@elx-element/common/envconf";
import {
  dispatchContentPositionChangeEvent,
  dispatchEvent,
  dispatchNotificationEvent,
  dispatchWrapperBackButtonClickEvent,
  dispatchWrapperStateChangeEvent,
} from "@elx-element/common/events/dispatchers";
import { changeScannerControl, dispatchScannerEvent } from "@elx-element/common/events/scanner";
import {
  GpsTrackingControl,
  MessageEventModel,
  NotificationEventModel,
  ScannerControl,
  SwitchModuleEventModel,
  WhispererRequest,
  WhispererResponse,
} from "@elx-element/common/events/types";
import { throwSpecificError } from "@elx-element/common/logger";
import { LogRecord } from "@elx-element/common/logger/types";
import {
  getStoredNetworkStatus,
  getWrapperBackButtonDefaultListenerActive,
  gpsTrackingHardDisabledSessionStorageKey,
  setElementInformationStorage,
  storeContentPosition,
  storeGpsTrackingHardDisable,
  storeLastGpsLocation,
  storeModulesAlerts,
} from "@elx-element/common/storage";
import { GpsLocationModel, ModuleAlert } from "@elx-element/common/storage/types";
import { LocalizedName, ModuleInterface, ModuleInterfaceEvent, RegisteredModule } from "@elx-element/common/types";
import { format } from "@elx-element/common/utils";
import { checkTokenAnyRoleExists } from "@elx-element/common/utils/token";

import { App, AppState, AppUrlOpen } from "@capacitor/app";
import { PluginListenerHandle, Plugins } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { Geolocation, PositionOptions, WatchPositionCallback } from "@capacitor/geolocation";

import * as CoreStoreTypes from "../../store/core/types";
import * as MessageStoreTypes from "../../store/message/types";
import { INewMessageModel, RouteModel } from "../../types";
import { ApplicationEventClient, ConfigurationClient, ModuleModel, WebContainerClient } from "../../types.generated";

import * as CoreStoreAction from "../../store/core/action";
import * as MessageStoreAction from "../../store/message/action";
import { selectNetworkStatus, selectRoutes, selectUser } from "../../store/selectors";

import userManager, { webStorage } from "../../auth/userManager";
import { WebContainerApplication, WebContainerErrors } from "../../enums";
import History from "../../history";
import Lang from "../../languages/lang";
import { writerClose } from "../scanner/nfcCore";
import {
  createDataWedgeProfile,
  onScanFinished,
  onScanPerformed,
  scanResultFunction,
} from "../scanner/zebraScannerCore";

const semver = require("semver");

const debugGps = getConfigurationBoolValue(window.env.webcontainer, "ENABLE_GPS_DEBUG");
const debugMobileEvent = getConfigurationBoolValue(window.env.webcontainer, "ENABLE_MOBILE_EVENT_DEBUG");
const debugEvent = getConfigurationBoolValue(window.env.webcontainer, "ENABLE_EVENT_DEBUG");
const zebraScannerContinualWork = getConfigurationBoolValue(window.env.webcontainer, "ZEBRA_SCANNER_CONTINUAL_WORK");
const { MachineLearning, ZebraScanner } = Plugins;

/** Identifikátor instance positionWatcheru */
let gpsPositionWatcherID: string | undefined;

// handle eventu skenování pro potřebu ukončení naslouchání
let zebraScannerHandle: PluginListenerHandle;

/**
 * Funkce pro přepnutí aktivního modulu pomoci události SwithModuleEvent.
 * Pokud komunikace není definovaná v rozhraní modulů, zobrazí se chyba.
 * Probíhá přepnutí na požadovaný modul a poté je vykonána požadovaná událost.
 * V offline režimu je přepínání modulů zakázáno! Změna se nevykoná.
 * @param eventDetail Data předána mezi aplikacemi
 * @param registeredModules Seznam registrovaných modulů
 * @param token token pro test role
 */
export const handleModuleSwitch = (eventDetail: SwitchModuleEventModel, registeredModules: Array<RegisteredModule>) => {
  if (getStoredNetworkStatus() === NetworkStatus.online) {
    // Test na existenci interface
    const targetModule = registeredModules.find(x => x.moduleId === eventDetail.targetModuleId);
    if (targetModule?.interface?.events?.find(x => x.eventName === eventDetail.eventName && x.confirmed)) {
      // Defaultní timeout pro připojení modulu a spuštění události
      let moduleConnectTimeout = getConfigurationNumberValue(window.env.webcontainer, "MODULE_LOAD_TIMEOUT");

      // validace dat - v případě neúspěchu nedojde k přesměrování na nový modul
      const eventDefinition = targetModule.interface.events.find(
        x =>
          x.moduleId === eventDetail.initiatorModuleId &&
          x.directionFlag === ModuleInterfaceDirectionFlag.in &&
          x.confirmed
      );
      let validationResult = true;
      if (eventDefinition && eventDefinition.objectDefinition) {
        eventDefinition.objectDefinition.forEach(x => {
          // test na existenci property
          if (eventDetail.data[x.name] === undefined) {
            validationResult = validationResult && false;
          }
          // test na formát property
          if (validationResult) {
            switch (x.type.toLowerCase()) {
              case "number":
                validationResult = !Number.isNaN(eventDetail.data[x.name]);
                break;
              case "datetime":
                validationResult = !Number.isNaN(Date.parse(eventDetail.data[x.name]));
                break;
              case "object":
                validationResult = typeof eventDetail.data[x.name] === "object";
                break;
              case "boolean":
                validationResult = typeof eventDetail.data[x.name] === "boolean";
                break;
              default:
                // string
                validationResult = typeof eventDetail.data[x.name] === "string";
                break;
            }
          }
        });
      }

      if (validationResult) {
        // požadavek na přeroutování modulu je validní

        // Routing na požadovaný modul pokud již není otevřený
        if (History.location.pathname !== `/${targetModule.route}`) {
          History.push(`/${targetModule.route}`);
        } else {
          // modul již je načtený a není potřeba čekat.
          moduleConnectTimeout = 0;
        }

        // Pokud existují data, pak se má po připojení modulu vygenerovat událost.
        if (eventDetail.data !== undefined) {
          // Čeká se na připojení modulu do stránky
          setTimeout(() => {
            // Pokud se modul podaří načíst v definovaném limitu, vytvoří se event. Pozná se to podle existence elementu s id "{id modulu}content"
            if (document.getElementById(`${eventDetail.targetModuleId}content`)) {
              dispatchEvent(
                `${eventDetail.targetModuleId.toLocaleUpperCase()}:${eventDetail.eventName.toLocaleUpperCase()}`,
                eventDetail.data,
                getConfigurationBoolValue(window.env.webcontainer, "ENABLE_EVENT_DEBUG")
              );
            } else {
              // Modul se nepodařilo spustit v požadovaném časovém limitu
              throwSpecificError(
                WebContainerErrors.executeActionFailed,
                `Executing an action failed. Module '${eventDetail.targetModuleId}' wasn't loaded in the required time limit.`,
                undefined
              );
            }
          }, moduleConnectTimeout);
        }
      } else {
        // Data eventu nesplňují předepsaný formát definovaný v konfiguračním souboru interface.json
        throwSpecificError(
          WebContainerErrors.executeActionFailed,
          "Unsupported communication type. Event data does not have the correct data type.",
          undefined
        );
      }
    } else {
      // Zobrazení chyby. Nepodporovaný typ komunikace
      throwSpecificError(
        WebContainerErrors.unsupportedCommunicationType,
        `Unsupported communication type. Event '${eventDetail.eventName}' wasn't confirmed.`,
        undefined
      );
    }
  }
};

/**
 * Handler pro odeslání zprávy z modulu.
 * Po odchycení eventu a předání dat se zpráva odešle k dalšímu zpracování v WebContainerApi.
 * @param eventDetail
 * @param client
 * @param sendNotificationText
 */
export const handleMessageSend = async (eventDetail: MessageEventModel, client: WebContainerClient, sTexts: Lang) => {
  const messageModel: INewMessageModel = {
    sender: eventDetail.sender,
    recipient: eventDetail.recipient,
    description: eventDetail.description,
    action: eventDetail.action,
    title: eventDetail.title,
    read: false,
    processed: false,
    severity: eventDetail.severity,
  };

  let success = true;
  try {
    await apiMan(client.message2(messageModel));
  } catch {
    success = false;
  }
  if (success) {
    dispatchNotificationEvent(new NotificationEventModel(sTexts.MESSAGE_SEND, Notification.success));
  }
};

/**
 * V případě spuštění aplikace v situaci, kdy url obsahuje routu modulu se přeroutuje na defaultRoute.
 * Hard refresh při otevřeném modulu není povolen.
 * @param routes seznam interních rout aplikace
 */
export const processInitialRedirect = (
  routes: Array<RouteModel>,
  userAuthorized: boolean,
  offlineMode: undefined | string | null = null
) => {
  if (offlineMode && window.location.pathname.indexOf(offlineMode) === 0 && userAuthorized) {
    return;
  }
  const defaultRoute = routes.find(x => x.default)?.route ?? "/";
  const allRoutes = routes.map(x => x.route);
  if (!allRoutes.includes(window.location.pathname.replace("/", ""))) {
    sessionStorage.setItem("urlBeforeRedirect", window.location.pathname);
    History.push(`/${defaultRoute}`);
  }
};

/**
 * Test dostupnosti url
 * @param url keycloak server url
 */
export const checkServerAvailability = async (url: string) =>
  new Promise<number | undefined>(resolve => {
    fetch(url)
      .then(response => {
        resolve(response.status);
      })
      .catch(_ => {
        resolve(undefined);
      });
  });

/**
 * initStorageAuth
 */
export const initStorageAuth = () => {
  // smazání informací o uživateli po unloadu stránky
  // dělalo to problém při refreshi stránky
  if (!window.inCapacitor) {
    window.addEventListener("unload", webStorage.removeAuthSync);
  }
};

/**
 * Nastavení použité platformy
 * @param dispatch
 */
export const setWebContainerPlatform = (
  store: any,
  dispatch: Dispatch<
    | CoreStoreTypes.setPlatform
    | CoreStoreTypes.setPlatformIsActive
    | CoreStoreTypes.clearNfcWriter
    | CoreStoreTypes.configureScanner
  >
) => {
  batch(async () => {
    // zjištění platformy a uložení hodnoty do sessionStorage
    await Device.getInfo().then(x => {
      let platform = WebContainerPlatform.web;

      if (x.platform in WebContainerPlatform) {
        platform = (WebContainerPlatform as any)[x.platform];
      }

      dispatch(CoreStoreAction.setPlatform(platform));
      if (platform !== WebContainerPlatform.web) {
        // updatuju capacitor/app, funkce již nebude dělat problémy s identifikací změny stavu aplikace
        App.removeAllListeners();
        // registrace listeneru na událost spuštění aplikace v mobilní platformě
        App.addListener("appUrlOpen", (data: AppUrlOpen) => {
          // reakce na Deep Linky
          if (data.url.includes(getConfigurationStringValue(window.env.webcontainer, "MOBILE_RETURN_URI"))) {
            if (data.url.includes("callback#state")) {
              // callback po návratu z keycloaku
              History.push(
                `/${data.url.replace(getConfigurationStringValue(window.env.webcontainer, "MOBILE_RETURN_URI"), "")}`
              );
            }
            if (data.url.includes("close")) {
              App.exitApp();
            }
          }
        });

        // obsluha mobilního tlačítka zpět, aktuálně toto pravidlo potlačuje chování mobilního tlačítka zpět
        App.addListener("backButton", (data: any) => {
          if (debugMobileEvent) {
            console.debug("debugMobileEvent: Back button event called", JSON.stringify(data));
          }

          // obsluha tlačítka zpět v situaci kdy je zapnuto skenování
          MachineLearning.isCameraActive().then((result: { value: boolean }) => {
            /**
             * Pokud je aktivní UI kamery nebo NFC skeneru, eventem provedu zrušení skenování.
             */
            if (
              result.value ||
              (store.getState().core.scannerSettings?.type === ScanType.nfcMessage &&
                store.getState().core.scannerSettings?.active)
            ) {
              // Vyvolání události zrušení skenování
              dispatchScannerEvent({ type: EventCode.scanAborted, scan: undefined });
            } else if (store.getState().core.nfcWriterSettings?.active) {
              /**
               * V případě že je aktivní UI pro obsluhu NFC writeru, zruším zápis a zavřu UI.
               */
              writerClose(dispatch);
            } else {
              /*
                                Tlačítko zpět je obsluhováno na dvou úrovních - defaultListener (container) nebo customListener (modul).
                                Jestliže připojený modul má zaregistrovaný customListener pro obsluhu této události, pak je potřeba potlačit chování
                                defaultListeneru (container). K tomu slouží informace v sessionStorage uložišti s názvem BlockBackButtonDefaultListener.
                                */
              dispatchWrapperBackButtonClickEvent(debugEvent);
            }
          });
        });

        // start kontinuálního snímání polohy při startu aplikace.
        initPositionUpdate();
        // start kontinuálního snímání výsledků skenování ze zebra skenerů
        initZebraScanner(dispatch);
        // defaultně vypnu chování skeneru, jako klávesnice (nově toto chování implementuju v zebraScanner.core.tsx)

        // změna stavu aplikace - vypnutí displeje, přesun aplikace do pozadí
        App.addListener("appStateChange", (appStatus: AppState) => {
          if (debugMobileEvent) {
            console.debug("debugMobileEvent: Mobile app state changed", appStatus);
          }

          // události budu vyvolávat, jen pokud se nový stav liší s aktuálním stavem aplikace (výchozí ve state je active) (zabrání zdvojení posluchačů událostí)
          if (appStatus.isActive !== store.getState().core.platformIsActive) {
            // nastavíme poslední stav do store
            dispatch(CoreStoreAction.setPlatformIsActive(appStatus.isActive));

            // vyvolání eventu o změně stavu aplikace. Tuto událost zpracovávají moduly samostatně.
            dispatchWrapperStateChangeEvent({ isActive: appStatus.isActive }, debugEvent);

            // na základě stavu aplikace budu přidávat a odebírat sledování polohy a výsledků ze zebra scanneru
            if (appStatus.isActive) {
              initZebraScanner(dispatch);
              if (
                gpsPositionWatcherID !== "denied" &&
                sessionStorage.getItem(gpsTrackingHardDisabledSessionStorageKey) ===
                  null /* neexistuje blokace gps lokalizace */
              ) {
                initPositionUpdate();
              }
            } else {
              clearZebraScanner();
              clearPositionUpdate();
            }
          }
        });
      }
    });
  });
};

/**
 * Ovládání snímání Gps trackování.
 * @param detail
 */
export const handleGpsTrackingColntrolChange = (detail?: GpsTrackingControl | undefined) => {
  if (detail) {
    if (detail.disabled) {
      clearPositionUpdate();
      storeGpsTrackingHardDisable(true);
    } else {
      initPositionUpdate();
      storeGpsTrackingHardDisable(false);
    }
  }
};

/**
 * Inicializace posluchače událostí zebra scanneru
 */
const initZebraScanner = (dispatch: Dispatch<CoreStoreTypes.configureScanner>) => {
  if (zebraScannerContinualWork) {
    const sc = new ScannerControl();
    sc.active = false;
    sc.regex = undefined;
    sc.replaceRules = undefined;
    sc.name = undefined;
    sc.type = ScanType.zebraBarcode;

    // inicializuji posluchač
    zebraScannerHandle = ZebraScanner.addListener("zebraScanEvent", (result: ZebraScanResult) =>
      scanResultFunction(result, sc, dispatch, onScanPerformed, onScanFinished)
    );
  }

  // vytvořím profil dataWedge a nastavím základní nastavení scanneru (nezávislé na kontinuálním snímání)
  createDataWedgeProfile().then(() => {
    // přesunuju sem, aby mi to defaultní nastavení nepřeráželo
    if (debugMobileEvent) {
      console.debug("changeNativeZebraBehaviour");
    }
    changeNativeZebraBehaviour(false);
  });
};

/**
 * Změna chování Zebra skeneru (vypnutí a zapnutí nativního chování skeneru - automatické skenování do focusovaného pole)
 * @param enableKeystroke povolit/zakázat nativní skenování
 */
const changeNativeZebraBehaviour = (enableKeystroke: boolean) => {
  const zebraControl = new ScannerControl();
  zebraControl.active = false;
  zebraControl.type = ScanType.zebraBarcode;
  zebraControl.scannerOptions = [
    enableKeystroke ? ScannerOption.enableZebraKeystrokeOutput : ScannerOption.disableZebraKeystrokeOutput,
  ];
  changeScannerControl(zebraControl, debugEvent);
};

/**
 * Uklizení posluchače událostí zebra scanneru
 */
const clearZebraScanner = () => {
  if (zebraScannerContinualWork) {
    zebraScannerHandle.remove();
  }
};

/**
 * Inicializace kontinuálního snímání GPS polohy
 */
const initPositionUpdate = () => {
  // Timeout v milisekundách pro získání další GPS souřadnice
  const timeout = getConfigurationNumberValue(window.env.webcontainer, "GEOLOCATION_TIMEOUT");
  // Prodleva v milisekundách mezi jednotlivými měřeními polohy
  const maximumAge = getConfigurationNumberValue(window.env.webcontainer, "GEOLOCATION_MAXIMUM_AGE");
  // Získává polohu přesně, je to ale časově i bateriově náročná operace
  const enableHighAccuracy = getConfigurationBoolValue(window.env.webcontainer, "GEOLOCATION_HIGH_ACCURACY");

  if (maximumAge >= 0) {
    // nastavení kontinuálního snímání polohy
    const locationOptions: PositionOptions = {
      timeout,
      maximumAge,
      enableHighAccuracy,
    };
    Geolocation.watchPosition(locationOptions, handlePositionUpdate)
      .then(id => {
        gpsPositionWatcherID = id;
        if (debugGps) {
          console.debug(
            `GPS continual watching ${gpsPositionWatcherID} started with configuration : ${JSON.stringify(
              locationOptions
            )}`
          );
        }
      })
      .catch(err => {
        throwSpecificError(CommonErrors.gpsPositionUnavailable, "GPS continual watch initialization failed.", err);
      });
  }
};

/**
 * zastavení kontinuálního snímání polohy
 */
const clearPositionUpdate = () => {
  if (debugGps) {
    console.debug(`GPS continual watch stop: ${gpsPositionWatcherID!}`);
  }
  Geolocation.clearWatch({ id: gpsPositionWatcherID! });
};

/**
 * zpracování nové GPS polohy
 * @param position - instance GeolocationPosition
 * @param err - případná chyba
 */
const handlePositionUpdate: WatchPositionCallback = (position, err) => {
  if (!err && position) {
    // transformace objektu
    const gpsLocation: GpsLocationModel = {
      position: `${position.coords.latitude}, ${position.coords.longitude}`,
      accuracy: position.coords.accuracy.toString(),
      timestamp: Date.now().toString(),
    };
    storeLastGpsLocation(gpsLocation);
  } else {
    if (debugGps) {
      console.debug("GPS handlePositionUpdate", err);
      console.debug("GPS, error message is =>", err.message);
    }

    // celá chybová zpráva v capacitoru 3 je nově "Location permission was denied"
    // pokud je v err.message objekt, tak aplikace padala na "e.message.includes is not a function"
    if (err.message && !(err.message instanceof Object) && err.message.includes("denied")) {
      // pokud nemám uživatelské povolení po první chybě tohoto typu zastavím snímání
      clearPositionUpdate();
      gpsPositionWatcherID = "denied";
    }
  }
};

/**
 * Obsluha stisknutí tlačítka zpět v mobilním zařízení (wrapper).
 * @param routes
 */
export const handleBackButtonClick = (routes: Array<RouteModel>) => {
  if (getWrapperBackButtonDefaultListenerActive() === true) {
    History.push(`/${routes.find(x => x.default)?.route}` ?? "/");
  }
};

/**
 * Proces potvrzování komunikace navzájem komunikujících modelů.
 * Na konci procesu musejí být všechny eventy a routy potvrzené => příznak confirmed = true.
 * @param registeredModules
 * @param dispatch
 */
export const moduleCompatibilityCheck = (
  registeredModules: RegisteredModule[],
  dispatch: Dispatch<CoreStoreTypes.confirmModuleInterfaceEvent>,
  texts: Lang,
  culture: string
) => {
  // cyklus potvrzování událostí
  registeredModules.forEach(module => {
    module.interface?.events?.forEach((event: ModuleInterfaceEvent) => {
      dispatch(CoreStoreAction.confirmModuleInterfaceEvent(module.moduleId, event));
    });
  });
  createModuleCompatibilityAlerts(registeredModules, texts, culture);
};

/**
 * Oznámení o nekompatibilitě modulů nebo chybějících součástech budou nalezeny a uloženy do sessionStorage.
 * @param registeredModules
 */
export const createModuleCompatibilityAlerts = async (
  registeredModules: RegisteredModule[],
  texts: Lang,
  culture: string
) => {
  const alerts: Array<ModuleAlert> = [];

  for (let i = 0; i < registeredModules.length; i++) {
    const module = registeredModules[i];

    // test na existenci nepotvrzených událostí ve vztahu volající - volaný modul
    module.interface?.events
      ?.filter(y => !y.confirmed && y.directionFlag === ModuleInterfaceDirectionFlag.out)
      ?.forEach(x => {
        // SWDE-9517 upozornění generujeme pouze pokud se jedná o require dependency
        // a nebo v případě optional dependency za předpokladu že jsou nainstalovány oba moduly
        if (x.required || registeredModules.some(y => y.moduleId === x.moduleId)) {
          alerts.push({
            moduleId: module.moduleId,
            alertMessage: format(texts.MODULE_INCOPATIBLE_EVENT, x.eventName, module.getLocalizedName(culture)),
            required: x.required ?? false,
          });
        }
      });

    // test na neexistenci konfiguračního souboru
    if (!module.configExists) {
      alerts.push({
        moduleId: module.moduleId,
        alertMessage: format(texts.CONFIG_FILE_MISSING, module.getLocalizedName(culture)),
        required: true,
      });
    }

    // cyklus pro test existence a kompatibility dependencies
    if (module.interface?.dependencies) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [dependentModuleId, wantedVersionExp] of Object.entries(module.interface.dependencies)) {
        // wantedVersionExp - výraz definující potřebnou verzi závislého modulu
        if (wantedVersionExp) {
          // dependencyName název závislosti
          let dependencyName: string | undefined;
          // currentVersion - současná verze závislosti
          let currentVersion: string | undefined;
          // závislost modulu na verzi webcontaineru
          if (dependentModuleId === process.env.REACT_APP_NAME) {
            dependencyName = process.env.REACT_APP_NAME;
            currentVersion = process.env.REACT_APP_VERSION;
          } else {
            // závislost modulu na verzi jiného modulu
            const dependentModule = registeredModules.find(x => x.moduleId === dependentModuleId);
            if (dependentModule) {
              // závislý modul je již nainstalován
              dependencyName = dependentModule.getLocalizedName(culture);
              currentVersion = dependentModule?.version;
            } else {
              // neznámá závislost
              dependencyName = dependentModuleId;
              currentVersion = undefined;
            }
          }

          if (
            (currentVersion && !semver.satisfies(currentVersion, wantedVersionExp)) ||
            (process.env.NODE_ENV === "production" && currentVersion === undefined)
          ) {
            alerts.push({
              moduleId: module.moduleId,
              alertMessage: format(
                texts.MODULE_MISSING_DEPENDENCY,
                `${module.getLocalizedName(culture)}@${module.version}`,
                `${dependencyName}@${wantedVersionExp}`
              ),
              required: true,
            });
          }
        }
      }
    }
  }

  // uložení do sessionStorage
  storeModulesAlerts(alerts);
};

/**
 * Kontrola platnosti licencí pro použití modulů
 * @param registeredModules
 */
export const checkModulesLicenseValidity = async (
  registeredModules: RegisteredModule[],
  texts: Lang,
  culture: string
) => {
  let warningLicenseMessage = "";
  let errorLicenseMessage = "";

  registeredModules
    .filter(x => x.requiredPermission && checkTokenAnyRoleExists(x.requiredPermission))
    .forEach(module => {
      if (module.licenseExpiring()) {
        warningLicenseMessage += `${module.getLocalizedName(culture) ?? module.moduleId}, `;
      } else if (module.licenseExpired()) {
        errorLicenseMessage += `${module.getLocalizedName(culture) ?? module.moduleId}, `;
      }
    });

  if (warningLicenseMessage.length > 0) {
    dispatchNotificationEvent(
      new NotificationEventModel(
        format(texts.LICENSE_NOTIFICATION, warningLicenseMessage.replace(/. $/, ".")),
        Notification.warning,
        undefined,
        undefined,
        undefined,
        true,
        WebContainerApplication.licenses
      ),
      debugEvent
    );
  }

  if (errorLicenseMessage.length > 0) {
    dispatchNotificationEvent(
      new NotificationEventModel(
        format(texts.LICENSE_ERROR_NOTIFICATION, errorLicenseMessage.replace(/. $/, ".")),
        Notification.error,
        undefined,
        undefined,
        undefined,
        true
      ),
      debugEvent
    );
  }
};

/**
 * Load interface souboru modulu definovaného parametrem moduleId
 * @param moduleId
 * @param modulePath
 */
export const loadModuleInterface = (moduleId: string, modulePath?: string) =>
  new Promise<{
    moduleId: string;
    interface: ModuleInterface;
    version: string | undefined;
    buildNumber: string | undefined;
  } | void>(resolve => {
    fetch(`${modulePath ?? ""}/modules/${moduleId}/interface.json?v=${Date.now()}`)
      .then(response => response.json())
      .then(response => {
        resolve({
          moduleId,
          interface: {
            ...((response?.interface ?? {}) as ModuleInterface),
            availableOfflineMode: !!response.availableOfflineMode,
          },
          version: response.version,
          buildNumber: response.buildNumber,
        });
      })
      .catch(() => {
        throwSpecificError(
          CommonErrors.missingDependency,
          `Module interface is missing in location ${modulePath ?? ""}/modules/${moduleId}/interface.json.`
        );
        resolve();
      });
  });

export const loadModuleWhisperer = (moduleId: string, modulePath?: string) =>
  new Promise<{
    moduleId: string;
  } | void>(resolve => {
    fetch(`${modulePath ?? ""}/modules/${moduleId}/whisperer.configuration.json?v=${Date.now()}`)
      .then(response => response.json())
      .then(response => {
        window.env.whisperer = { ...window.env.whisperer, [moduleId]: response };
        const script = document.createElement("script");
        script.src = `${modulePath ?? ""}/modules/${moduleId}/whisperer.js?v=${Date.now()}`;
        script.async = true;
        script.type = "module";
        script.setAttribute("data-script-type", "module-whisperer-script");
        if (!document.body.contains(script)) {
          document.body.appendChild(script);
        }
      })
      .catch(() => {
        throwSpecificError(
          CommonErrors.missingDependency,
          `Whisperer configuration is missing in location ${
            modulePath ?? ""
          }/modules/${moduleId}/whisperer.configuration.json`
        );
      });

    resolve();
  });

/**
 * Load konfiguračního souboru modulu definovaného parametrem moduleId
 * @param moduleId
 * @param modulePath
 */
export const loadModuleConfiguration = (
  moduleId: string,
  dispatch: Dispatch<CoreStoreTypes.setModuleConfigExists>,
  modulePath?: string
) =>
  new Promise<void>(resolve => {
    fetch(`${modulePath ?? ""}/modules/${moduleId}/configuration.js?v=${Date.now()}`)
      .then(response => {
        if (response.headers.get("content-type")?.includes("application/javascript")) {
          dispatch(CoreStoreAction.setModuleConfigExists(moduleId));
        } else {
          throwSpecificError(
            CommonErrors.missingDependency,
            `Module configuration is missing in location ${modulePath ?? ""}/modules/${moduleId}/configuration.js`
          );
        }
        resolve();
      })
      .catch(_ => {
        throwSpecificError(
          CommonErrors.missingDependency,
          `Module configuration is missing in location ${modulePath ?? ""}/modules/${moduleId}/configuration.js`
        );
        resolve();
      });
  });

/**
 * Provede načtení rozhraní všech zaregistrovaných modulů. Pro každý modul je následně  provedena registrace metod z rozhraní.
 * @param registeredModules Seznam zaregistrovaných modulů.
 * @param registeredModules
 * @param dispatch
 * @param modulePath
 */
export const loadModulesInterfaces = async (
  registeredModules: RegisteredModule[],
  dispatch: Dispatch<CoreStoreTypes.setModuleInterface | CoreStoreTypes.setModuleConfigExists>,
  modulePath?: string
) => {
  const interfacesJsonData = await Promise.all(
    registeredModules
      .filter(x => x.externalModule)
      .map(async module => {
        if (module.externalModule) {
          await loadModuleConfiguration(module.moduleId, dispatch);
        }
        return loadModuleInterface(module.moduleId, modulePath);
      })
  );

  await Promise.all(
    // eslint-disable-next-line array-callback-return, consistent-return
    interfacesJsonData.map(async moduleInterface => {
      if (moduleInterface) {
        // pokud interface povoluje použití našeptávače, připojíme skript
        if (moduleInterface.interface.services?.whisperer) {
          await loadModuleWhisperer(moduleInterface.moduleId, modulePath);
        }
        return dispatch(
          CoreStoreAction.setModuleInterface(
            moduleInterface.moduleId,
            moduleInterface.interface,
            moduleInterface.version,
            moduleInterface.buildNumber
          )
        );
      }
    })
  );
};

/**
 * Load definic modulů z API
 * @param client
 * @param dispatch
 */
export const loadModules = async (
  client: ConfigurationClient,
  dispatch: Dispatch<CoreStoreTypes.setRegisteredModules>
) => {
  const data: Array<RegisteredModule> = [];
  const modules = (await apiMan(client.getModules())) as ModuleModel[];
  if (modules) {
    modules.forEach(x => {
      data.push(
        new RegisteredModule(
          x.moduleId!,
          x.route!,
          x.localizedNames as Array<LocalizedName>,
          x.externalModule,
          x.showOnMobile,
          x.permission,
          x.tileIcon,
          x.tileColor,
          x.widgets,
          x.license?.expiration?.toString(),
          x.license?.type,
          x.order
        )
      );
    });
    /** Registrace modulů do core storu */
    dispatch(CoreStoreAction.setRegisteredModules(data));
  }
};

/**
 * Load notifikací
 * @param client
 * @param dispatch
 */
export const loadNotifications = async (
  client: WebContainerClient,
  dispatch: Dispatch<MessageStoreTypes.addNotifications>
) => {
  const notifications = await apiMan(client.notificationAll(1000, 0));
  if (notifications) {
    dispatch(MessageStoreAction.addNotifications(notifications));
  }
};

/**
 * Uložení uživatelů aplikace, kterým lze adresovat zprávu.
 * @param client
 * @param dispatch
 */
export const loadUsers = async (client: WebContainerClient, dispatch: Dispatch<MessageStoreTypes.addUsers>) => {
  const users = await apiMan(client.user(1000, 0));
  if (users) {
    dispatch(MessageStoreAction.addUsers(users));
  }
};

/**
 * Load zpráv
 * @param client
 * @param dispatch
 */
export const loadMessages = async (client: WebContainerClient, dispatch: Dispatch<MessageStoreTypes.addMessages>) => {
  const messages = await apiMan(client.messageAll(1000, 0, undefined, undefined, false));
  if (messages) {
    dispatch(MessageStoreAction.addMessages(messages));
  }
};

/**
 * Load všech typů notifikací
 * @param client
 * @param dispatch
 */
export const loadApplicationEvents = async (
  client: ApplicationEventClient,
  dispatch: Dispatch<MessageStoreTypes.addApplicationEvents>
) => {
  const applicationEvents = await apiMan(client.applicationEvent());
  if (applicationEvents) {
    dispatch(MessageStoreAction.addApplicationEvents(applicationEvents));
  }
};

/**
 * Load odebíraných typů notifikací konkrétního uživatele
 * @param client
 * @param dispatch
 */
export const loadSubcriptions = async (
  client: WebContainerClient,
  dispatch: Dispatch<MessageStoreTypes.addSubcriptions>
) => {
  const subcriptions = await apiMan(client.eventSubscriptionAll(1000, 0));
  if (subcriptions) {
    dispatch(MessageStoreAction.addSubcriptions(subcriptions));
  }
};

/**
 * Load nastavení dashboardu konkrétního uživatele
 * @param client
 * @param dispatch
 */
export const loadDashboardSettings = async (
  client: ConfigurationClient,
  dispatch: Dispatch<CoreStoreTypes.setDasboardSettings>
) => {
  const dashboardSettings = await apiMan(client.getDashboardSettings());
  if (dashboardSettings) {
    dispatch(CoreStoreAction.setDashboardSettings(dashboardSettings));
  }
};

/**
 * Initial data load
 * @param webContainerClient
 * @param applicationEventClient
 * @param dispatch
 */
export const initialLoadData = async (
  webContainerClient: WebContainerClient,
  applicationEventClient: ApplicationEventClient,
  dispatch: Dispatch<
    | MessageStoreTypes.addSubcriptions
    | MessageStoreTypes.addNotifications
    | MessageStoreTypes.addMessages
    | MessageStoreTypes.addUsers
    | MessageStoreTypes.addApplicationEvents
  >
) => {
  batch(async () => {
    loadUsers(webContainerClient, dispatch);
    loadMessages(webContainerClient, dispatch);
    loadNotifications(webContainerClient, dispatch);
    loadApplicationEvents(applicationEventClient, dispatch);
    loadSubcriptions(webContainerClient, dispatch);
  });
};

/**
 * Metoda pro uchovávání koordinátů obsahové plochy.
 * @param isDesktopView
 */
export const handleStoreContentPosition = (isDesktopView: boolean) => {
  setTimeout(() => {
    const contentPosition = {
      offsetLeft: isDesktopView ? document.getElementById("wC-navigation")?.offsetWidth ?? 0 : 0,
      offsetTop: document.getElementById("wC-header")?.offsetHeight ?? 0,
    };
    storeContentPosition(contentPosition);
    dispatchContentPositionChangeEvent(
      contentPosition,
      getConfigurationBoolValue(window.env.webcontainer, "ENABLE_EVENT_DEBUG")
    );
  }, 0);
};

export const useNetworkConnection = (createNotificationTokenExpiring: () => void) => {
  const networkStatus = useSelector(selectNetworkStatus);
  const user = useSelector(selectUser);
  const routes = useSelector(selectRoutes);
  const [alerted, setAlerted] = useState(false);

  useEffect(() => {
    if (!user) return;

    if (networkStatus === NetworkStatus.offline) {
      // dočasné pozastavení obnovení tokenu
      userManager.stopSilentRenew();
      if (alerted) return;
      let timeoutAlert = user!.expires_at! - new Date().getTime() / 1000;

      if (timeoutAlert <= 0) {
        return;
      }
      if (timeoutAlert < 500) {
        timeoutAlert = 3;
      }
      const refTimeoutAlert = setTimeout(() => {
        setAlerted(true);
        createNotificationTokenExpiring();
      }, timeoutAlert * 1000);

      // výjmutí hlášky, zablokováno z důvodu, že useEffect umožnujě jak undefined, tak destruct funkci
      // eslint-disable-next-line consistent-return
      return () => {
        clearTimeout(refTimeoutAlert);
      };
    }
    setAlerted(false);
    /**
     * nutno řešit takto (ne redux), protože App se je nahozeno dříve, než login/logout/callback
     * spravně by mělo být router -> (callback|login|logout|stranka, ktera primo nepotrebuje uzivatele|loggeduser -> App s přihlášeným či pin uživatelem)
     */
    const noPrivates = new RegExp(
      `^/(${routes
        .filter(item => !item.private)
        .map(item => item.route)
        .join("|")})`
    );
    if (!`${document.location.pathname}`.match(noPrivates)) {
      if (!user) {
        userManager.signinRedirect();
      } else if (!user.expired) {
        userManager.startSilentRenew();
      }
    }
  }, [networkStatus, createNotificationTokenExpiring]);
};

/**
 * capacitor ma neustale tendenci prehodit url zpet na install.html, ac uz bylo preroutovano do hlavni casti aplikace
 */
export const CapacitorUrlValidator = () => {
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.match(/^\/install.html.*/)) {
      history.replace("/dashboard");
    }
  }, [location.pathname]);
  // nemohu vrátit false a nechci vracet div či jiný element
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <Fragment />;
};

/**
 * Hlídání komunikace našeptávače. Pokud je volán neexistující našeptávač, vyrobíme notifikaci s upozoprněním že našeptávač neexistuje.
 * @param eventDetail
 * @param registeredModules
 */
export const handleWhispererRequest = (
  whispererRequest: WhispererRequest | undefined,
  registeredModules: Array<RegisteredModule>,
  texts: Lang
) => {
  if (whispererRequest) {
    if (registeredModules.find(x => x.moduleId === whispererRequest.moduleName && x.interface?.services?.whisperer)) {
      // našeptávač existuje
    } else {
      dispatchNotificationEvent(
        new NotificationEventModel(
          format(texts.WHISPERER_ISNOT_ACTIVE, whispererRequest.moduleName),
          Notification.warning
        )
      );
      // naslouchájícímu modulu vrátíme prázdná data
      window.dispatchEvent(
        new CustomEvent(`${EventCode.whispererCompleted}:${whispererRequest.moduleName}`, {
          detail: { totalCount: 0, items: [] } as WhispererResponse,
        })
      );
    }
  }
};

// Řešení požadavků na poskytnutí sdílených informací o element prostředí mezi modulem a webcontainerem.
export const handleElementInformationRequest = (
  informationType: ElementInformationType,
  registeredModules: Array<RegisteredModule>
) => {
  // eslint-disable-next-line default-case
  switch (informationType) {
    case ElementInformationType.modules:
      setElementInformationStorage(JSON.stringify(registeredModules));
      break;
    default:
      setElementInformationStorage("");
      break;
  }
};

/**
 * Pokus o identifikaci některých anonymních chyb. Pokud se chybu podaří identifikovat, je přiřazen konkretkní chybový kód.
 * @param message Znění chyby
 */
export const identifyAnonymousError = (error: LogRecord): LogRecord => {
  // Potlačení zobrazování chyby při vypnutém snímání GPS na mobilním zařízení.
  if (
    typeof error.message === "string" &&
    error.message.replace(/\s+/g, "").replace(/'+/g, "").replace(/"+/g, "").toLowerCase() ===
      "{message:locationunavailable}"
  ) {
    error.errorCode = `webUi.common.${CommonErrors.ignoredAnonymousError}`;
  }
  // Při nuceném odříznutí služby (restart kontejneru v azure) je ukončeno spojení SignalR a vygenerována následující chyba.
  // Webcontainer automaticky restartuje spojení. Proto není nutné tuto chybu zobrazovat uživateli.
  else if (JSON.stringify(error.message).includes("WebSocket closed with status code: 1006 ().")) {
    error.errorCode = `webUi.common.${CommonErrors.ignoredAnonymousError}`;
  }
  // V některých případech SignalR byla komunikace neočekávaně ukončena následující chybou.
  // Webcontainer spojení automaticky obnoví, viz debug console.
  else if (
    JSON.stringify(error.message).includes("Error: Server timeout elapsed without receiving a message from the server.")
  ) {
    error.errorCode = `webUi.common.${CommonErrors.ignoredAnonymousError}`;
  } else if (JSON.stringify(error.message).includes("Error: Failed to complete negotiation with the server")) {
    error.errorCode = `webUi.common.${CommonErrors.ignoredAnonymousError}`;
  }
  // Vůbec se nepodařilo navázat SignalR spojení, nebude obnoveno.
  else if (JSON.stringify(error.message).includes("Error: Failed to start the connection")) {
    error.errorCode = `webUi.common.${WebContainerErrors.signalRConnectionError}`;
  }

  return error;
};
