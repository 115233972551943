import { WebContainerPlatform } from "@elx-element/common/enums";
import { IScan, NfcWriterControl, ScannerControl } from "@elx-element/common/events/types";
import { ModuleInterface, ModuleInterfaceEvent, RegisteredModule } from "@elx-element/common/types";

import { DashboardSettingsModel } from "../../types.generated";

export interface coreState {
  mainMenuOpen: boolean;
  registeredModules: Array<RegisteredModule>;
  registeredModulesLoaded: boolean;
  activeModuleId: string | undefined;
  platform: WebContainerPlatform | undefined;
  platformIsActive: boolean;
  scannerSettings: ScannerControl | undefined;
  scannerLastScan: IScan | undefined;
  nfcWriterSettings: NfcWriterControl | undefined;
  dashboardSettings: Array<DashboardSettingsModel>;
  openErrorDetailReference: string | undefined;
  webContainerUpdated: boolean | undefined;
  moduleUpdated: boolean | undefined;
}

export const SET_OPEN_ERROR_DETAIL_REFERENCE = "SET_OPEN_ERROR_DETAIL_REFERENCE";
export interface setOpenErrorDetailReference {
  type: typeof SET_OPEN_ERROR_DETAIL_REFERENCE;
  payload: string | undefined;
}

export const SET_MAIN_MENU_OPEN = "SET_MAIN_MENU_OPEN";
export interface setMainMenuOpen {
  type: typeof SET_MAIN_MENU_OPEN;
  payload: boolean;
}

export const SET_REGISTERED_MODULES = "SET_REGISTERED_MODULES";
export interface setRegisteredModules {
  type: typeof SET_REGISTERED_MODULES;
  payload: Array<RegisteredModule>;
}

export const SET_REGISTERED_MODULES_LOADED = "SET_REGISTERED_MODULES_LOADED";
export interface setRegisteredModulesLoaded {
  type: typeof SET_REGISTERED_MODULES_LOADED;
}

export const SET_MODULE_INTERFACE = "SET_MODULE_INTERFACE";
export interface setModuleInterface {
  type: typeof SET_MODULE_INTERFACE;
  payload: {
    moduleId: string;
    interface: ModuleInterface;
    version: string | undefined;
    buildNumber: string | undefined;
  };
}

export const CONFIRM_MODULE_INTERFACE_EVENT = "CONFIRM_MODULE_INTERFACE_EVENT";
export interface confirmModuleInterfaceEvent {
  type: typeof CONFIRM_MODULE_INTERFACE_EVENT;
  payload: {
    moduleId: string;
    event: ModuleInterfaceEvent;
  };
}

export const SET_ACTIVE_MODULE = "SET_ACTIVE_MODULE";
export interface setActiveModule {
  type: typeof SET_ACTIVE_MODULE;
  payload: string | undefined;
}

export const SET_PLATFORM = "SET_PLATFORM";
export interface setPlatform {
  type: typeof SET_PLATFORM;
  payload: WebContainerPlatform;
}

export const SET_PLATFORM_IS_ACTIVE = "SET_PLATFORM_IS_ACTIVE";
export interface setPlatformIsActive {
  type: typeof SET_PLATFORM_IS_ACTIVE;
  payload: boolean;
}

export const SET_MODULE_CONFIG_EXISTS = "SET_MODULE_CONFIG_EXISTS";
export interface setModuleConfigExists {
  type: typeof SET_MODULE_CONFIG_EXISTS;
  payload: string;
}

export const CONFIGURE_SCANNER = "CONFIGURE_SCANNER";
export interface configureScanner {
  type: typeof CONFIGURE_SCANNER;
  payload: ScannerControl;
}

export const CLEAR_SCANNER = "CLEAR_SCANNER";
export interface clearScanner {
  type: typeof CLEAR_SCANNER;
}

export const CLEAR_NFC_WRITER = "CLEAR_NFC_WRITER";
export interface clearNfcWriter {
  type: typeof CLEAR_NFC_WRITER;
}

export const SET_SCANNER_LAST_SCAN = "SET_SCANNER_LAST_SCAN";
export interface setScannerLastScan {
  type: typeof SET_SCANNER_LAST_SCAN;
  payload: IScan | undefined;
}

export const CONFIGURE_NFC_WRITER = "CONFIGURE_NFC_WRITER";
export interface configureNfcWriter {
  type: typeof CONFIGURE_NFC_WRITER;
  payload: NfcWriterControl;
}

export const SET_DASHBOARD_SETTINGS = "SET_DASHBOARD_SETTINGS";
export interface setDasboardSettings {
  type: typeof SET_DASHBOARD_SETTINGS;
  payload: Array<DashboardSettingsModel>;
}

export const SET_WEBCONTAINER_UPDATED = "SET_WEBCONTAINER_UPDATED";
export interface setWebContainerUpdated {
  type: typeof SET_WEBCONTAINER_UPDATED;
  payload: boolean;
}

export const SET_MODULE_UPDATED = "SET_MODULE_UPDATED";
export interface setModuleUpdated {
  type: typeof SET_MODULE_UPDATED;
  payload: boolean;
}

export type coreActionTypes =
  | setMainMenuOpen
  | setActiveModule
  | setRegisteredModules
  | setRegisteredModulesLoaded
  | confirmModuleInterfaceEvent
  | setModuleInterface
  | setPlatform
  | setModuleConfigExists
  | configureScanner
  | clearScanner
  | setScannerLastScan
  | configureNfcWriter
  | clearNfcWriter
  | setDasboardSettings
  | setOpenErrorDetailReference
  | setPlatformIsActive
  | setModuleUpdated
  | setWebContainerUpdated;
