/* eslint-disable import/prefer-default-export */
import { PropsWithChildren, useEffect, useRef, useState } from "react";

import { User, UserManager } from "oidc-client";
import { useDispatch, useSelector, useStore } from "react-redux";
import { OidcProvider } from "redux-oidc";

import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import BaseTextField from "@elx-element/ui/Inputs/BaseTextField";

import { selectNetworkStatus, selectOfflineBaseUrl, selectRoutes, selectTexts } from "../../store/selectors";

import useStyles from "./styles";

import { webStorage } from "../../auth/userManager";

type LoggedUserProps = PropsWithChildren<{
  userManager: UserManager;
}>;

enum LoadingState {
  Loading,
  BiometricsPinRequired,
  BiometricsPinTrying,
  PinRequired,
  PinTrying,
  PinError,
  Loaded,
}

/**
 * bude potreba jeste poresit, zda vubec vim, zda mam nacteneho uzivatele, pin se pri odregitraci SW samozrejme neodmaze.
 * @param props
 * @returns
 */
export const LoggedUser = (props: LoggedUserProps) => {
  const routes = useSelector(selectRoutes);

  const [loadingUser, setLoadingUser] = useState(() => {
    const noPrivates = new RegExp(
      `^/(${routes
        .filter(item => !item.private)
        .map(item => item.route)
        .join("|")})`
    );

    if (`${window.location.pathname}`.match(noPrivates)) {
      return LoadingState.Loaded;
    }
    return LoadingState.Loading;
  });

  const { classes, cx } = useStyles();
  const mountedComponent = useRef(true);
  const [pin, setPin] = useState("");
  const store = useStore();
  const dispatch = useDispatch();
  // zajímá mě jen při startu aplikace
  const offlineUrl = useSelector(selectOfflineBaseUrl, () => true);
  const texts = useSelector(selectTexts);
  const networkStatus = useSelector(selectNetworkStatus);

  const { userManager, children } = props;

  useEffect(() => {
    const successUser = (item: User | null) => {
      if (item) {
        /**
         * Jsme v offline modu, pak je nutno skočit přímo na modul
         */
        if (offlineUrl) {
          if (window.location.pathname.indexOf(offlineUrl) !== 0) {
            window.location.pathname = offlineUrl;
            return;
          }
        }

        dispatch({
          type: "redux-oidc/USER_FOUND",
          payload: item,
        });

        if (!item.expired) {
          // zkouska o prodloužení tokenu
          userManager.startSilentRenew();
        }
      }
      setLoadingUser(LoadingState.Loaded);
    };

    const errorUser = () => {
      setLoadingUser(LoadingState.Loaded);
    };

    switch (loadingUser) {
      case LoadingState.Loading:
        userManager
          .getUser()
          .then(async item => {
            const offlineAuthAvailable = await webStorage.offlineAuthAvailable();
            const biometricsAvailable = await webStorage.biometricsAvailable();
            if (!item && offlineAuthAvailable) {
              setLoadingUser(biometricsAvailable ? LoadingState.BiometricsPinRequired : LoadingState.PinRequired);
            } else {
              successUser(item);
            }
          })
          .catch(errorUser);
        break;
      case LoadingState.BiometricsPinTrying:
        webStorage
          .unlock({
            title: texts.PIN_BIOMETRICS_TITLEDIALOG,
            desc: texts.PIN_BIOMETRICS_DESCDIALOG,
          })
          .then(item => {
            if (item.Unlocked) {
              userManager.getUser().then(successUser).catch(errorUser);
            } else {
              setLoadingUser(LoadingState.BiometricsPinRequired);
            }
          });
        break;
      case LoadingState.BiometricsPinRequired:
      case LoadingState.PinRequired:
        break;
      case LoadingState.PinTrying:
        webStorage
          .unlock({ pin })
          .then(item => {
            if (item.Unlocked) {
              userManager.getUser().then(successUser).catch(errorUser);
            } else {
              setLoadingUser(LoadingState.PinError);
            }
          })
          .catch(() => {
            setLoadingUser(LoadingState.PinError);
          });
        break;
      default:
        break;
    }
  }, [loadingUser]);

  useEffect(
    () => () => {
      mountedComponent.current = false;
    },
    []
  );

  const byBiometrics = [LoadingState.BiometricsPinRequired, LoadingState.BiometricsPinTrying].indexOf(loadingUser) > -1;

  switch (loadingUser) {
    case LoadingState.Loading:
      return (
        <Backdrop open>
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    case LoadingState.BiometricsPinTrying:
    case LoadingState.BiometricsPinRequired:
    case LoadingState.PinRequired:
    case LoadingState.PinTrying:
    case LoadingState.PinError:
      return (
        <Dialog open classes={{ paper: cx(classes.dialogPaper, classes.xsFullscreenDialog) }}>
          <DialogTitle className={classes.dialogTitle}>
            {byBiometrics ? texts.PIN_BIOMETRICS : texts.PIN}
            <Typography variant="body1">{byBiometrics ? texts.PIN_BIOMETRICS_INFO : texts.PIN_INFO}</Typography>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            {byBiometrics || (
              <BaseTextField
                culture="cs-CZ"
                error={loadingUser === LoadingState.PinError}
                className={classes.contentItem}
                value={pin}
                onKeyUp={e => {
                  if (e.key === "Enter") {
                    setLoadingUser(LoadingState.PinTrying);
                  }
                }}
                onChange={(e: any) => {
                  setPin(e.target.value);
                  setLoadingUser(LoadingState.PinRequired);
                }}
                helperText={loadingUser === LoadingState.PinError ? texts.PIN_ERROR : ""}
              />
            )}
          </DialogContent>
          {LoadingState.BiometricsPinTrying !== loadingUser && (
            <DialogActions>
              <Grid container spacing={1} className={classes.dialogActions}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Button
                    disabled={!pin.trim() && !byBiometrics}
                    onClick={_ =>
                      setLoadingUser(byBiometrics ? LoadingState.BiometricsPinTrying : LoadingState.PinTrying)
                    }
                    color="primary"
                    fullWidth
                  >
                    {byBiometrics ? texts.PIN_BIOMETRICS_USE : texts.PIN_USE}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Button
                    disabled={networkStatus === "offline"}
                    onClick={_ => {
                      sessionStorage.setItem("urlBeforeRedirect", window.location.pathname);
                      window.location.href = "/login";
                    }}
                    color="primary"
                    fullWidth
                  >
                    {texts.KEYCLOAK_USE}
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          )}
        </Dialog>
      );
    default:
      return (
        <OidcProvider store={store} userManager={userManager}>
          {children}
        </OidcProvider>
      );
  }
};
