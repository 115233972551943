import Oidc, { UserManager, UserManagerSettings } from "oidc-client";

import { authStorageKey } from "@elx-element/common/storage";

import WebStorage from "./webStorage";

class WebContainerUserManager extends UserManager {
  // eslint-disable-next-line
  get _userStoreKey(): string {
    return authStorageKey;
  }
}

const webStorage = new WebStorage();

const userManagerConfig: UserManagerSettings = {
  // eslint-disable-next-line camelcase
  client_id: window.env.webcontainer.KEYCLOAK_CLIENT_ID,
  // eslint-disable-next-line camelcase
  redirect_uri: `${window.env.webcontainer.PUBLIC_URL}/callback`,
  // eslint-disable-next-line camelcase
  response_type: "id_token token",
  scope: window.env.webcontainer.KEYCLOAK_SCOPE,
  authority: window.env.webcontainer.AUTH_URL,
  // eslint-disable-next-line camelcase
  silent_redirect_uri: `${window.location.origin}/auth/silentrenew.htm`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  // eslint-disable-next-line camelcase
  post_logout_redirect_uri: window.location.origin,
  userStore: webStorage,
  monitorSession: true,
  /**
   *  Nastavení popupNavigator a iframeNavigator se dejě pouze při spuštění aplikace v android wrapperu.
   *  Aktuálně není jiná cesta jak v tomto místě zjistit, že aplikace je spuštěna pod androidem, jelikož capacitor zde ještě není přístupný.
   */
  popupNavigator: window.inCapacitor && !window.disableCordova ? new Oidc.CordovaPopupNavigator() : undefined,
  iframeNavigator: window.inCapacitor && !window.disableCordova ? new Oidc.CordovaIFrameNavigator() : undefined,
  // eslint-disable-next-line camelcase
  popup_redirect_uri: `${window.location.origin}/callback`,
  popupWindowTarget: "_blank",
  popupWindowFeatures: "",
  accessTokenExpiringNotificationTime: 60,
};

const userManager = new WebContainerUserManager(userManagerConfig);

// Registrace eventu pro výmaz session storage po expiraci tokenu.
userManager.events.addAccessTokenExpired(async () => {
  console.debug("element: AccessTokenExpired");

  // Uživatel má pin, není potřeba token dávat pryč
  if (await webStorage.offlineAuthAvailable()) {
    return;
  }

  // Pokus o potlačení generování chyb po probuzení PC z režimu spánku, přičemž v mezičase došlo k vypršení tokenu.
  // Za normálních okolností, kdy funguje obnova tokenu by tato situace neměla nastat.
  // V krajních případech, kdy se aplikace chová nestandardně, vymažeme session storage a přesměrujeme na login stránku.
  await webStorage.removeAll();
  sessionStorage.clear();
  window.location.href = `${window.location.origin}/login`;
});

userManager.events.addSilentRenewError(async () => {
  console.debug("element: SilentRenewError");
});

export { webStorage };
export default userManager as UserManager;
