import React, { useEffect } from "react";

import { useSelector } from "react-redux";

import CircularProgress from "@mui/material/CircularProgress";

import { getConfigurationNumberValue } from "@elx-element/common/envconf";

import { selectPlatform, selectUser } from "../../store/selectors";

import useAppStyles from "../app/styles";

import * as CoreUser from "../user/core";

const LogoutPage = () => {
  const { classes } = useAppStyles();
  const platform = useSelector(selectPlatform);
  const user = useSelector(selectUser);

  // Timeout komponenty. Pokud loading probíhá déle než stanovaný čas, zobrazí se chyba.
  const [timeoutOccured, setTimeoutOccured] = React.useState<boolean>(false);
  let timer: any;
  const timeout = getConfigurationNumberValue(window.env.webcontainer, "KEYCLOAK_CALLBACK_TIMEOUT");

  useEffect(() => {
    timer = setTimeout(() => setTimeoutOccured(true), timeout);
    CoreUser.onLogout(platform!, user!);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (timeoutOccured) {
      window.location.href = `${window.location.origin}/login`;
    }
  }, [timeoutOccured]);

  return <div className={classes.centeredContent}>{!timeoutOccured && <CircularProgress />}</div>;
};

export default LogoutPage;
