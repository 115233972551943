import { NetworkStatus, NetworkStrength, NetworkType } from "@elx-element/common/enums";
import { getConfigurationBoolValue, getConfigurationStringValue } from "@elx-element/common/envconf";
import { dispatchCultureChangeEvent, dispatchNetworkStatusChangeEvent } from "@elx-element/common/events/dispatchers";
import { getAppInitialCulture, getMachineSettings, getStoredNetworkStatus, setMachineSettings, storeCulture, storeNetworkStatus, storeNetworkStrength, storeNetworkType } from "@elx-element/common/storage";

import {
  mainActionTypes,
  mainState,
  SET_CULTURE,
  SET_INFO_POPUP_OPEN,
  SET_MACHINE_SETTINGS,
  SET_NETWORK_STATUS,
  SET_NETWORK_STRENGTH,
  SET_NETWORK_TYPE,
  SET_OFFLINE_MODE,
} from "./types";

import CallbackPage from "../../components/auth/CallbackPage";
import LoginPage from "../../components/auth/LoginPage";
import LogoutPage from "../../components/auth/LogoutPage";
import Dashboard from "../../components/dashboard";
import User from "../../components/user";
import csCZ from "../../languages/csCZ";
import enUS from "../../languages/enUS";
import huHU from "../../languages/huHU";
import Lang from "../../languages/lang";
import plPL from "../../languages/plPL";

const debugEvent = getConfigurationBoolValue(window.env.webcontainer, "ENABLE_EVENT_DEBUG");
const initialCulture = getAppInitialCulture(process.env.REACT_APP_NAME!);
const initialNetworkStatus = getStoredNetworkStatus();

/**
 * Webcontainer UI prozatím umí pouze 2 jazykové mutace cs-CZ a en-US.
 * Při rozšiřování slovníku je potřeba upravit tuto funkci.
 */
const getLanguageDictionary = (culture: string): Lang => {
  switch (culture) {
    case "cs-CZ":
      return csCZ;
    case "hu-HU":
      return huHU;
    case "pl-PL":
      return plPL;
    default:
      return enUS;
  }
};

const initialState: mainState = {
  cultures: getConfigurationStringValue(window.env[process.env.REACT_APP_NAME!], "CULTURES").split(";"),
  culture: initialCulture,
  texts: getLanguageDictionary(initialCulture),
  routes: [
    { route: "callback", component: CallbackPage, private: false, default: false },
    { route: "login", component: LoginPage, private: false, default: false },
    { route: "logout", component: LogoutPage, private: false, default: false },
    { route: "dashboard", component: Dashboard, private: true, default: true },
    { route: "user", component: User, private: true, default: false },
  ],
  networkStatus: window.inCapacitor ? NetworkStatus.offline : NetworkStatus.online,
  networkType: window.inCapacitor ? NetworkType.none : NetworkType.browser,
  networkStrength: window.inCapacitor ? NetworkStrength.none : NetworkStrength.good,
  offlineMode: !!localStorage.getItem("baseOfflineUrl"),
  baseOfflineUrl: localStorage.getItem("baseOfflineUrl"),
  machineSettings: getMachineSettings(),
  infoPopupOpen: false
};

// Uložení kultury do sessionStorage pro předání modulům
storeCulture(initialCulture);

// Nastavení stavu aplikace - offline stav: aplikace obsahuje neodeslaná data, je potřeba dokončit datovou výměnu při připojení k síti
storeNetworkStatus(initialNetworkStatus === null ? NetworkStatus.online : initialNetworkStatus);

export default function mainReducer(state = initialState, action: mainActionTypes): mainState {
  switch (action.type) {
    case SET_CULTURE:
      storeCulture(action.payload);
      dispatchCultureChangeEvent(debugEvent);
      return {
        ...state,
        culture: action.payload,
        texts: getLanguageDictionary(action.payload),
      };
    case SET_NETWORK_STATUS:
      storeNetworkStatus(action.payload);
      dispatchNetworkStatusChangeEvent(debugEvent);
      return {
        ...state,
        networkStatus: action.payload,
      };
    case SET_OFFLINE_MODE:
      if (!action.payload) {
        localStorage.removeItem("baseOfflineUrl");
      } else {
        localStorage.setItem("baseOfflineUrl", action.payload);
      }

      return {
        ...state,
        offlineMode: !!action.payload,
        baseOfflineUrl: action.payload,
      };
    case SET_NETWORK_STRENGTH:
      storeNetworkStrength(action.payload);
      return {
        ...state,
        networkStrength: action.payload,
      };
    case SET_NETWORK_TYPE:
      storeNetworkType(action.payload);
      return {
        ...state,
        networkType: action.payload,
      };
    case SET_MACHINE_SETTINGS:
      setMachineSettings(action.payload);
      return {
        ...state,
        machineSettings: action.payload,
      };
    case SET_INFO_POPUP_OPEN:
      return {
        ...state,
        infoPopupOpen: action.payload,
      };
    default:
      return state;
  }
}
