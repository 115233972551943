import { NetworkStrength, NetworkType } from "capacitor-elinkx-network";

import { NetworkStatus } from "@elx-element/common/enums";
import { MachineSettings } from "@elx-element/common/storage/types";

import { RouteModel } from "../../types";

import Lang from "../../languages/lang";

export interface mainState {
  cultures: Array<string>;
  culture: string;
  texts: Lang;
  routes: Array<RouteModel>;
  networkStatus: NetworkStatus;
  networkStrength: NetworkStrength;
  networkType: NetworkType;
  offlineMode: boolean;
  baseOfflineUrl?: string | null;
  machineSettings: MachineSettings;
  infoPopupOpen: boolean;
}

export const SET_CULTURE = "SET_CULTURE";
export interface setCulture {
  type: typeof SET_CULTURE;
  payload: string;
}

export const SET_NETWORK_STATUS = "SET_NETWORK_STATUS";
export interface setNetworkStatus {
  type: typeof SET_NETWORK_STATUS;
  payload: NetworkStatus;
}

export const SET_OFFLINE_MODE = "SET_OFFLINE_MODE";
export interface setOfflineMode {
  type: typeof SET_OFFLINE_MODE;
  payload?: string;
}

export const SET_NETWORK_TYPE = "SET_NETWORK_TYPE";
export interface setNetworkType {
  type: typeof SET_NETWORK_TYPE;
  payload: NetworkType;
}

export const SET_NETWORK_STRENGTH = "SET_NETWORK_STRENGTH";
export interface setNetworkStrength {
  type: typeof SET_NETWORK_STRENGTH;
  payload: NetworkStrength;
}

export const SET_MACHINE_SETTINGS = "SET_MACHINE_SETTINGS";
export interface setMachineSettings {
  type: typeof SET_MACHINE_SETTINGS;
  payload: MachineSettings;
}

export const SET_INFO_POPUP_OPEN = "SET_INFO_POPUP_OPEN";
export interface setInfoPopupOpen {
  type: typeof SET_INFO_POPUP_OPEN;
  payload: boolean;
}

export type mainActionTypes =
  | setCulture
  | setNetworkStatus
  | setOfflineMode
  | setNetworkStrength
  | setNetworkType
  | setMachineSettings
  | setInfoPopupOpen;
