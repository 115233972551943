import { getConfigurationStringValue } from "@elx-element/common/envconf";
import { AudioType } from "@elx-element/common/events/types";

export function handlePlaySound(params: CustomEventInit<any>) {
  if (params?.detail) playSound(params.detail);
}

export function playSound(type: AudioType) {
  switch (type) {
    case "success":
      playSuccessSound();
      break;
    case "success2":
      playSuccess2Sound();
      break;
    case "warning":
      playWarningSound();
      break;
    case "fail":
      playFailSound();
      break;
    case "info":
      playInfoSound();
      break;
    default:
      break;
  }
}

function playSuccessSound() {
  const soundPath = getConfigurationStringValue(window.env[process.env.REACT_APP_NAME!], "SUCCESS_AUDIO_SOURCE");
  playAudio(soundPath);
}

function playSuccess2Sound() {
  const soundPath = getConfigurationStringValue(window.env[process.env.REACT_APP_NAME!], "SUCCESS_2_AUDIO_SOURCE");
  playAudio(soundPath);
}

function playFailSound() {
  const soundPath = getConfigurationStringValue(window.env[process.env.REACT_APP_NAME!], "FAIL_AUDIO_SOURCE");
  playAudio(soundPath);
}

function playWarningSound() {
  const soundPath = getConfigurationStringValue(window.env[process.env.REACT_APP_NAME!], "WARNING_AUDIO_SOURCE");
  playAudio(soundPath);
}

function playInfoSound() {
  const soundPath = getConfigurationStringValue(window.env[process.env.REACT_APP_NAME!], "INFO_AUDIO_SOURCE");
  playAudio(soundPath);
}

function playAudio(data: string) {
  const audio = new Audio(data);
  audio.play();
}
