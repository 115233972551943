import React, { useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";

import { IconButton, Menu, MenuItem } from "@mui/material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

import { apiMan } from "@elx-element/common/apiClient/apiMan";
import { useElementContext } from "@elx-element/common/elementContext";
import { MessageTarget } from "@elx-element/common/enums";
import {
  dispatchModuleSwitchEvent,
  dispatchUnsubscribeNotificationEvent,
} from "@elx-element/common/events/dispatchers";
import { SwitchModuleEventModel } from "@elx-element/common/events/types";
import { throwSpecificError } from "@elx-element/common/logger";
import { format } from "@elx-element/common/utils";
import BaseIcon from "@elx-element/ui/DataDisplay/BaseIcon";

import {
  mdiBellOutline,
  mdiClose,
  mdiCogOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEmailOutline,
  mdiEyeOffOutline,
  mdiOpenInApp,
  mdiPaperclip,
} from "@mdi/js";

import { MessageModel, NotificationModel, WebContainerClient } from "../../types.generated";

import * as MessageStoreAction from "../../store/message/action";
import { selectTexts } from "../../store/selectors";

import useStyles from "./styles";

import { WebContainerErrors } from "../../enums";
import History from "../../history";

interface Props {
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  type: MessageTarget;
  data: Array<MessageModel> | Array<NotificationModel>;
  onClose: () => void;
}

const MessagePopover = (props: Props) => {
  const dispatch = useDispatch();

  const { classes, cx } = useStyles();
  const texts = useSelector(selectTexts);
  const { formatDateTime } = useElementContext();
  const [anchorContextMenuEl, setAnchorContextMenuEl] = React.useState<null | HTMLElement>(null);
  const [openedMessage, setOpenedMessage] = React.useState<MessageModel | NotificationModel | undefined>(undefined);

  const abortController = useRef(new AbortController());
  const mounted = useRef<boolean>(true);

  useEffect(
    () => () => {
      abortController.current.abort();
      mounted.current = false;
    },
    []
  );

  const openContextMenu = (event: React.MouseEvent<HTMLElement>, message: MessageModel | NotificationModel) => {
    setOpenedMessage(message);
    setAnchorContextMenuEl(event.currentTarget);
  };
  const closeContextMenu = () => {
    setOpenedMessage(undefined);
    setAnchorContextMenuEl(null);
  };

  /// Spuštění připojené akce ke zprávě
  const handleActionClick = (messageId: number, action: string) => {
    // spuštění akce a označení zprávy jako zprocesované
    executeConnectedAction(messageId, action);
    /// zavře popup okno
    props.onClose();
  };

  /// Zavření zprávy - označení jako přečtené
  const hideMessage = () => {
    closeContextMenu();
    if (openedMessage) {
      apiMan(new WebContainerClient({ abortSignal: abortController.current.signal }).message(openedMessage!.id, true));
      dispatch(MessageStoreAction.removeMessage(openedMessage!.id));
    }

    // pokud je seznam zpráv prázdný, automaticky zavře i popup zpráv
    if (props.data.length === 0) {
      props.onClose();
    }
  };

  /// Zavření notifikace - označení jako přečtené
  const hideNotification = () => {
    closeContextMenu();
    if (openedMessage) {
      apiMan(
        new WebContainerClient({ abortSignal: abortController.current.signal }).notification2(openedMessage!.id, true)
      );
      dispatch(MessageStoreAction.removeNotification(openedMessage!.id));
    }

    // pokud je seznam notifikací prázdný, automaticky zavře i popup notifikací
    if (props.data.length === 0) {
      props.onClose();
    }
  };

  /// Odhlášení odběru notifikace
  const unsubscribeNotification = () => {
    closeContextMenu();
    if (openedMessage) {
      dispatchUnsubscribeNotificationEvent(openedMessage as NotificationModel);
    }
  };

  /// Zavření okna
  const onCloseInternal = () => {
    closeContextMenu();
    props.onClose();
  };

  /// Kliknutí na položku v submenu
  const handleSubMenuActionClick = () => {
    if (openedMessage && openedMessage!.action) {
      onCloseInternal();
      handleActionClick(openedMessage!.id, openedMessage!.action);
    }
  };

  /**
   * Spuštění připojené akce ke zprávě a označení zprávy jako zpracované
   */
  const executeConnectedAction = (messageId: number, action: string) => {
    let actionModel: SwitchModuleEventModel | undefined;
    try {
      actionModel = JSON.parse(action);
    } catch {
      throwSpecificError(
        WebContainerErrors.unsupportedActionData,
        "Action data error.",
        `messageId: ${messageId}, actionData: ${action}`
      );
    }
    if (actionModel) {
      /// nastavení stavu zprávy - přečtěno
      apiMan(
        new WebContainerClient({ abortSignal: abortController.current.signal }).message(messageId, undefined, true)
      );
      /// vyvolá event pro otevření modulu.
      dispatchModuleSwitchEvent(actionModel, true);
    }
  };

  useEffect(
    () => () => {
      closeContextMenu();
    },
    []
  );

  return (
    <Popover
      classes={{
        paper: classes.root,
      }}
      open={props.open}
      onClose={onCloseInternal}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <div className={classes.container}>
        {props.type === MessageTarget.message && (
          <>
            <div className={classes.header}>
              <IconButton className={classes.closeButton} onClick={props.onClose} title={texts.CLOSE}>
                <BaseIcon data={mdiClose} />
              </IconButton>
              <Typography variant="h6">
                {texts.MESSAGES} ( {props.data.length} )
              </Typography>
            </div>
            <div className={classes.messages}>
              {(props.data as MessageModel[]).map((m, idx) => (
                <div className={classes.message} key={idx}>
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={e => openContextMenu(e, m)}
                    className={cx(classes.subSettingsButton, "moreVertIcon")}
                  >
                    <BaseIcon data={mdiDotsVertical} />
                  </IconButton>
                  <BaseIcon
                    className={classes.notificationIcon}
                    titleAccess={texts.TEXT_MESSAGE}
                    data={m.action !== undefined ? mdiPaperclip : mdiEmailOutline}
                  />
                  <div className={classes.messageText}>
                    <Typography variant="body2">
                      <b>{format(texts.MESSAGE_FROM_USER, m.sender, m.title)}</b>
                    </Typography>
                    <Typography variant="body2">{m.description}</Typography>
                    <Typography variant="caption">{formatDateTime(m.created)}</Typography>
                  </div>
                </div>
              ))}
            </div>
            <Menu
              anchorEl={anchorContextMenuEl}
              keepMounted
              open={Boolean(anchorContextMenuEl)}
              onClose={closeContextMenu}
            >
              {openedMessage?.action && (
                <MenuItem onClick={() => handleSubMenuActionClick()}>
                  <BaseIcon className={classes.submenuIcon} data={mdiOpenInApp} />
                  {texts.SHOW}
                </MenuItem>
              )}
              <MenuItem onClick={() => hideMessage()}>
                <BaseIcon className={classes.submenuIcon} data={mdiDeleteOutline} />
                {texts.DELETE}
              </MenuItem>
            </Menu>
          </>
        )}

        {props.type === MessageTarget.notification && (
          <>
            <IconButton
              aria-label="settings"
              color="secondary"
              size="small"
              className={classes.settingsButton}
              title={texts.SETTINGS}
              onClick={() => {
                props.onClose();
                History.push("/user");
              }}
            >
              <BaseIcon data={mdiCogOutline} />
            </IconButton>
            <div className={classes.header}>
              <IconButton className={classes.closeButton} onClick={props.onClose} title={texts.CLOSE}>
                <BaseIcon data={mdiClose} />
              </IconButton>
              <Typography variant="h6">
                {texts.NOTIFICATION} ({props.data.length})
              </Typography>
            </div>
            <div className={classes.messages}>
              {(props.data as NotificationModel[]).map((m, idx) => (
                <div className={classes.message} key={idx}>
                  <div>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={e => openContextMenu(e, m)}
                      className={cx(classes.subSettingsButton, "moreVertIcon")}
                    >
                      <BaseIcon data={mdiDotsVertical} />
                    </IconButton>
                    <BaseIcon
                      className={classes.notificationIcon}
                      titleAccess={texts.SYSTEM_NOTIFICATION}
                      data={mdiBellOutline}
                    />
                    <div className={classes.messageText}>
                      <Typography variant="body2">
                        <b>{m.title}</b>
                      </Typography>
                      <Typography variant="body2">{m.description}</Typography>
                      <Typography variant="caption">{formatDateTime(m.created)}</Typography>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <Menu
              anchorEl={anchorContextMenuEl}
              keepMounted
              open={Boolean(anchorContextMenuEl)}
              onClose={closeContextMenu}
            >
              <MenuItem onClick={() => hideNotification()}>
                <BaseIcon className={classes.submenuIcon} data={mdiDeleteOutline} />
                {texts.DELETE_NOTIFICATION}
              </MenuItem>
              <MenuItem onClick={() => unsubscribeNotification()}>
                <BaseIcon className={classes.submenuIcon} data={mdiEyeOffOutline} />
                {texts.STOP_SHOW}
              </MenuItem>
            </Menu>
          </>
        )}
      </div>
    </Popover>
  );
};

export default MessagePopover;
