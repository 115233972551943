import { Fragment, useCallback, useEffect, useState } from "react";

import axios from "axios";
import { useSnackbar } from "notistack";
import { batch, useDispatch, useSelector, useStore } from "react-redux";
import { Router } from "react-router-dom";

import { Box, CircularProgress, IconButton, Link, Typography, useMediaQuery, useTheme } from "@mui/material";

import { useElementContext } from "@elx-element/common/elementContext";
import { CommonErrors, NetworkStatus, Notification } from "@elx-element/common/enums";
import { getConfigurationBoolValue, getConfigurationStringValue } from "@elx-element/common/envconf";
import { dispatchNotificationEvent } from "@elx-element/common/events/dispatchers";
import {
  registerElementInformationListener,
  registerGPSTrackingControlListener,
  registerMessageListener,
  registerModuleSwitchListener,
  registerNotificationListener,
  registerPlayAudioListener,
  registerTitleChangeListener,
  registerUnsubscribeNotificationListener,
  registerWhispererRequestedListener,
  registerWrapperBackButtonClickDefaultListener,
  unregisterPlayAudioListener,
} from "@elx-element/common/events/listeners";
import { registerNfcWriterControlEventListener } from "@elx-element/common/events/nfc";
import { registerScannerControlEventListener } from "@elx-element/common/events/scanner";
import { NotificationEventModel } from "@elx-element/common/events/types";
import { registerConsoleLogger } from "@elx-element/common/logger";
import { getModulesAlerts } from "@elx-element/common/storage";
import { format } from "@elx-element/common/utils";
import BaseIcon from "@elx-element/ui/DataDisplay/BaseIcon";

import { mdiCertificateOutline, mdiClose, mdiConnection, mdiLightbulbOutline } from "@mdi/js";

import { ApplicationEventClient, ConfigurationClient, WebContainerClient } from "../../types.generated";

import { AppState } from "../../store";
import * as CoreStoreAction from "../../store/core/action";
import {
  isMobilePlatform,
  selectActiveModule,
  selectCulture,
  selectInfoModalIsOpen,
  selectModuleUpdated,
  selectNetworkStatus,
  selectOfflineBaseUrl,
  selectOfflineMode,
  selectPlatform,
  selectRegisteredModules,
  selectRegisteredModulesLoaded,
  selectRoutes,
  selectTexts,
  selectToken,
  selectUser,
  selectUserAuthorized,
  selectUserExpired,
  selectWebContainerUpdated,
} from "../../store/selectors";

import useStyles from "./styles";

import * as CoreApp from "./core";
import {
  checkModulesLicenseValidity,
  handleGpsTrackingColntrolChange,
  handleStoreContentPosition,
  identifyAnonymousError,
  useNetworkConnection,
} from "./core";

import { WebContainerApplication, WebContainerErrors } from "../../enums";
import { handlePlaySound } from "../../events/audio";
import History from "../../history";
import Lang from "../../languages/lang";
import * as CoreSignalR from "../../signalr";
import TryLaterPage from "../auth/TryLaterPage";
import ErrorDetail from "../error";
import Header from "../header";
import InfoModal from "../infoPopup";
import Navigation from "../navigation";
import Routes from "../routes";
import CameraScanner from "../scanner/CammeraScanner";
import * as CoreNfc from "../scanner/nfcCore";
import NfcScanner from "../scanner/NfcScanner";
import NfcWriter from "../scanner/NfcWriter";
import * as CoreScanner from "../scanner/scannerCore";
import * as CoreUser from "../user/core";

const debugEvent = getConfigurationBoolValue(window.env.webcontainer, "ENABLE_EVENT_DEBUG");

const App = () => {
  const store = useStore<AppState>();
  const theme = useTheme();
  const dispatch = useDispatch();
  const texts = useSelector(selectTexts);
  const snackbar = useSnackbar();
  const userAuthorized = useSelector(selectUserAuthorized);
  const user = useSelector(selectUser);
  const token = useSelector(selectToken);
  const openApp = useSelector(selectActiveModule); // reference na otevřený modul
  const registeredModules = useSelector(selectRegisteredModules);
  const registeredModulesLoaded = useSelector(selectRegisteredModulesLoaded);
  const culture = useSelector(selectCulture);
  const routes = useSelector(selectRoutes);
  const offlineMode = useSelector(selectOfflineMode);
  const offlineUrl = useSelector(selectOfflineBaseUrl);
  const platform = useSelector(selectPlatform);
  const mobilePlatform = useSelector(isMobilePlatform);
  CoreSignalR.useSignalR(token, new WebContainerClient({}), texts.YOU_HAVE_MESSAGES, dispatch);
  const viewPortMobile = useMediaQuery(theme.breakpoints.down("md"));
  const networkStatus = useSelector(selectNetworkStatus);
  const webcontainerUpdated = useSelector(selectWebContainerUpdated);
  const moduleUpdated = useSelector(selectModuleUpdated);
  const userExpired = useSelector(selectUserExpired);
  const infoModalIsOpen = useSelector(selectInfoModalIsOpen);
  const [serviceWorkerActiveInDebugMode, setServiceWorkerActiveInDebugMode] = useState(false);
  const { classes } = useStyles();
  const { checkTokenAnyRoleExists } = useElementContext();
  /**
   * Registrace eventů
   */
  useEffect(
    () => {
      navigator.serviceWorker?.ready?.then(() => {
        if (process.env.NODE_ENV !== "production") {
          setServiceWorkerActiveInDebugMode(true);
        }
      });

      if (registeredModulesLoaded) {
        registerTitleChangeListener();
        registerModuleSwitchListener(event =>
          CoreApp.handleModuleSwitch(event, store.getState().core.registeredModules)
        );
        registerNotificationListener(event => showError(event, store.getState().main.texts), showNotification);
        registerMessageListener(event =>
          CoreApp.handleMessageSend(event, new WebContainerClient({}), store.getState().main.texts)
        );
        registerUnsubscribeNotificationListener(event =>
          CoreUser.handleUnsubscribeNotificationId(
            event,
            new WebContainerClient({}),
            dispatch,
            store.getState().main.texts
          )
        );
        registerPlayAudioListener(handlePlaySound);
        checkModulesLicenseValidity(store.getState().core.registeredModules, texts, culture);

        // Listener komunikace volání našeptávače (detekuje volání neexistujícího whispereru).
        registerWhispererRequestedListener(event =>
          CoreApp.handleWhispererRequest(event.detail, store.getState().core.registeredModules, texts)
        );

        // Listener pro požadavky na poskytnutí sdílených informací o element prostředí mezi modulem a webcontainerem.
        registerElementInformationListener(event =>
          CoreApp.handleElementInformationRequest(event.detail, store.getState().core.registeredModules)
        );

        if (mobilePlatform) {
          // Listener na ovládání komponenty scanneru je aktivní pouze při přístupu z mobilní aplikace
          registerScannerControlEventListener(event =>
            CoreScanner.handleScannerControlEvent(event, dispatch, store.getState().core.scannerSettings)
          );
          // Listener pro tlačítko zpět na mobilním zařízení
          registerWrapperBackButtonClickDefaultListener(() => CoreApp.handleBackButtonClick(routes));
          // Listener pro NFC writer
          registerNfcWriterControlEventListener(event => CoreNfc.handleNfcWriterControlEvent(event, dispatch));
          // Listener pro potlačení skenování Gps polohy
          registerGPSTrackingControlListener(e => handleGpsTrackingColntrolChange(e.detail));
        }
      }
      return () => {
        unregisterPlayAudioListener(handlePlaySound);
      };
    },
    [registeredModulesLoaded] /* Pokud se změní tento parametr, kod se provede znovu */
  );

  /**
   * Funkce pro zobrazení notifikace 5 minut před koncem platnosti tokenu.
   */
  const createNotificationTokenExpiring = useCallback(async () => {
    showNotification(
      new NotificationEventModel(
        texts.ACCESS_TOKEN_IS_EXPIRING,
        Notification.warning,
        undefined,
        undefined,
        undefined,
        true
      )
    );
  }, [user, offlineMode]);

  /**
   * Zjišťování, zda došlo k aktualizace modulu, či webcontaineru
   */
  useEffect(() => {
    let finished = false;
    let timeout = setTimeout(() => {});
    const process = async () => {
      try {
        const responseWebContainer = await fetch(`/interface.json?refresh=${Date.now()}`, {
          cache: "reload",
          headers: {
            "x-cache": "reload",
          },
        }).then(item => item.json());

        let responseModule: any;

        if (openApp) {
          responseModule = await fetch(`/modules/${openApp.moduleId}/interface.json?refresh=${Date.now()}`, {
            cache: "reload",
            headers: {
              "x-cache": "reload",
            },
          }).then(item => item.json());
        }

        batch(() => {
          const newModuleUpdated = responseModule?.updateRequired ?? false;
          const newWebContainerUpdated = responseWebContainer?.updateRequired ?? false;
          if (newModuleUpdated !== !!moduleUpdated) {
            dispatch(CoreStoreAction.setModuleUpdated(!!newModuleUpdated));
          }
          if (newWebContainerUpdated !== !!webcontainerUpdated) {
            dispatch(CoreStoreAction.setWebContainerUpdated(!!newWebContainerUpdated));
          }
        });
      } catch (e) {
        console.debug(e);
      }
      if (!finished) {
        timeout = setTimeout(process, 60000 * 5);
      }
    };
    process();
    return () => {
      finished = true;
      clearTimeout(timeout);
    };
  }, [openApp?.moduleId, moduleUpdated, webcontainerUpdated]);
  /**
   * Registrace loggeru a listeneru jeho změn, přesměrování na root při startu aplikace, ošetření výpadku internetového spojení
   */
  useEffect(() => {
    CoreApp.processInitialRedirect(routes, userAuthorized, offlineUrl);
    CoreApp.setWebContainerPlatform(store, dispatch);
    registerConsoleLogger(
      window.env.webcontainer,
      process.env.REACT_APP_NAME!,
      /* kolekce ignorovaných chyb, pro které se negeneruje notifikace */
      [
        `webUi.common.${CommonErrors.ignoredAnonymousError}`,
        `webUi.common.${WebContainerErrors.signalRConnectionError}`,
        `WebcontainerApi.Business.Wiki.DocumentNotFound`, // 404 load dat nápovědy
      ],
      identifyAnonymousError
    );
    CoreApp.initStorageAuth();
    window.addEventListener("resize", () => handleStoreContentPosition(true));
  }, []);

  // ošetření výpadku internetového spojení
  useNetworkConnection(createNotificationTokenExpiring);

  /**
   * Logika pro zabránění změny routy při výpadku internetového spojení.
   * Lze přeroutovat pouze v rámci aktuálně otevřeného modulu.
   */
  useEffect(() => {
    let isValid = true;
    const unblock = History.block(({ pathname }) => {
      if (
        networkStatus === NetworkStatus.offline &&
        (!openApp || !pathname.startsWith(`/${openApp.moduleId}`)) &&
        !offlineMode &&
        !pathname.startsWith(`/${offlineUrl}`)
      ) {
        isValid = false;
      }
      // if is valid we can allow the navigation
      if (isValid) {
        // we can now unblock
        unblock();
        // proceed with the blocked navigation
        History.push(pathname);
      }
      // prevent navigation
      return false;
    });
    return unblock;
  }, [networkStatus]);

  /**
   * InitialLoad - Načtení registrací modulů - ověření kompatibility modulů - Načtení zpráv, notifikací, uživatelů
   */
  useEffect(
    () => {
      if (userAuthorized) {
        if (!registeredModulesLoaded) {
          batch(async () => {
            await CoreApp.loadModules(
              new ConfigurationClient(
                {},
                undefined,
                axios.create({
                  headers: {
                    "x-cache-required": "yes",
                  },
                })
              ),
              dispatch
            );
            await CoreApp.loadDashboardSettings(
              new ConfigurationClient(
                {},
                undefined,
                axios.create({
                  headers: {
                    "x-cache-required": "yes",
                  },
                })
              ),
              dispatch
            );

            // postupné stahování interface souborů dostupných modulů
            await CoreApp.loadModulesInterfaces(
              store.getState().core.registeredModules,
              dispatch,
              getConfigurationBoolValue(window.env.webcontainer, "LOAD_SERVER_MODULES")
                ? getConfigurationStringValue(window.env.webcontainer, "SERVER_MODULES_PATH")
                : undefined
            );

            // Spustí test kompatibility modulů a sestaví seznam případných chyb
            CoreApp.moduleCompatibilityCheck(store.getState().core.registeredModules, dispatch, texts, culture);

            // nastavení příznaku load complete
            dispatch(CoreStoreAction.setRegisteredModulesLoaded());

            if (userAuthorized && offlineUrl && window.location.pathname.indexOf(offlineUrl) !== 0) {
              History.push(`${offlineUrl}`);
            }
          });
        }
      }
    },
    [userAuthorized] /* Pokud se změní tento parametr, kod se provede znovu */
  );

  /**
   * Nastavení TITLE aplikace.
   */
  useEffect(() => {
    document.title =
      openApp?.getLocalizedName(culture) ?? getConfigurationStringValue(window.env.webcontainer, "DEFAULT_TITLE");
  }, [openApp]);

  /**
   * V případě potíží s kompatibilitou vygenerujeme notifikaci s upozorněním.
   * Notifikaci nezobrazujeme v případě že chybné moduly jsou skryty.
   */
  useEffect(() => {
    if (registeredModulesLoaded) {
      // Load users, messages, notifications, applicationEvents, subcriptions
      CoreApp.initialLoadData(new WebContainerClient({}), new ApplicationEventClient({}), dispatch);

      // Zobrazení upozornění na nekompatibilitu
      const moduleIncompatibilityAlerts = getModulesAlerts();
      if (
        userAuthorized &&
        moduleIncompatibilityAlerts.length > 0 &&
        registeredModules
          .filter(
            x =>
              !x.requiredPermission ||
              (checkTokenAnyRoleExists(x.requiredPermission!) &&
                (window.innerWidth >= theme.breakpoints.values.md || x.allowOnMobile))
          )
          .some(x => moduleIncompatibilityAlerts.map(y => y.moduleId).includes(x.moduleId))
      ) {
        dispatchNotificationEvent(
          new NotificationEventModel(texts?.INCOMPATIBILITY_NOTIFICATION, Notification.warning),
          debugEvent
        );
      }
    }
  }, [registeredModulesLoaded]);

  /**
   * Funkce pro zobrazení chyby v aplikaci
   * @param eventDetail
   */
  const showError = (eventDetail: NotificationEventModel, sTexts: Lang) => {
    const guid = Date.now().toString();
    // notifikace se zobrazují pouze autorizovanému uživateli.
    if (userAuthorized) {
      snackbar.enqueueSnackbar(
        <div>
          {format(sTexts.ERROR_OCCURED, eventDetail.application)}

          {/* tímto zápisem předejdu chybám, "Objects are not valid as a React Child", v případě, že je v eventDetail?.message obsažen objekt */}
          <div className="message">{`${eventDetail?.message}`}</div>
        </div>,
        {
          className: classes.errorNotification,
          key: guid,
          variant: eventDetail?.type,
          autoHideDuration: null,
          action: (
            <>
              {eventDetail.errorReference && (
                <IconButton
                  color="inherit"
                  title={texts.RESOLVE}
                  onClick={() => {
                    snackbar.closeSnackbar(guid);
                    dispatch(CoreStoreAction.setOpenErrorDetailReference(eventDetail.errorReference));
                  }}
                >
                  <BaseIcon data={mdiLightbulbOutline} />
                </IconButton>
              )}
              {eventDetail.application === WebContainerApplication.licenses && (
                <IconButton
                  color="inherit"
                  title={texts.RESOLVE}
                  onClick={() => {
                    snackbar.closeSnackbar(guid);
                    History.push(
                      getConfigurationStringValue(window.env.webcontainer, "MODULE_LICENSE_PROBLEM_SOLVING_URL")
                    );
                  }}
                >
                  <BaseIcon data={mdiCertificateOutline} />
                </IconButton>
              )}
              <IconButton
                aria-label="close"
                color="inherit"
                title={texts.CLOSE}
                onClick={() => snackbar.closeSnackbar(guid)}
              >
                <BaseIcon data={mdiClose} />
              </IconButton>
            </>
          ),
        }
      );
    }
  };

  /**
   * Funkce pro zobrazení notifikace v aplikaci
   * @param eventDetail
   */
  const showNotification = (eventDetail: NotificationEventModel) => {
    const guid = Date.now().toString();
    snackbar.enqueueSnackbar(
      <div className={classes.notificationDetail}>
        <div>{eventDetail?.message}</div>
        {eventDetail?.description && <div className="description">{eventDetail?.description}</div>}
      </div>,
      {
        key: guid,
        variant: eventDetail?.type,
        anchorOrigin: eventDetail?.position,
        autoHideDuration: eventDetail?.useCloseButton ? null : undefined,
        action: eventDetail?.useCloseButton ? (
          <>
            {eventDetail.application === WebContainerApplication.licenses && (
              <IconButton
                color="inherit"
                title={texts.RESOLVE}
                onClick={() => {
                  snackbar.closeSnackbar(guid);
                  History.push(
                    getConfigurationStringValue(window.env.webcontainer, "MODULE_LICENSE_PROBLEM_SOLVING_URL")
                  );
                }}
              >
                <BaseIcon data={mdiCertificateOutline} />
              </IconButton>
            )}
            <IconButton
              aria-label="close"
              color="inherit"
              title={texts.CLOSE}
              onClick={() => snackbar.closeSnackbar(guid)}
            >
              <BaseIcon data={mdiClose} />
            </IconButton>
          </>
        ) : undefined,
      }
    );
  };

  return (
    <Box className={classes.root}>
      {platform !== undefined && (
        <Router history={History}>
          <CoreApp.CapacitorUrlValidator />
          {userAuthorized && <Header />}
          {userAuthorized && <Navigation />}
          <main id="mainContent" className={classes.content}>
            {serviceWorkerActiveInDebugMode ? (
              <div className={classes.offlineNotificationBox}>
                <BaseIcon data={mdiConnection} />
                {texts.SERVICEWORKER_ACTIVE}
              </div>
            ) : (
              false
            )}
            {userAuthorized && networkStatus === NetworkStatus.offline && (
              <div className={classes.offlineNotificationBox}>
                <BaseIcon data={mdiConnection} />
                OFFLINE - {texts.NETWORK_STATUS_OFFLINE}
              </div>
            )}
            {userAuthorized && (moduleUpdated || webcontainerUpdated) && (
              <div className={classes.offlineNotificationBox}>
                <BaseIcon data={mdiConnection} />
                {[
                  moduleUpdated ? (
                    <a
                      href="/install.html?reload"
                      key={1}
                      onClick={() => {
                        sessionStorage.setItem("urlBeforeRedirect", window.location.pathname);
                      }}
                    >
                      {texts.MODULE_UPDATE_AVAILABLE}
                    </a>
                  ) : (
                    false
                  ),
                  webcontainerUpdated ? (
                    <a
                      href="/install.html?reload"
                      key={2}
                      onClick={() => {
                        sessionStorage.setItem("urlBeforeRedirect", window.location.pathname);
                      }}
                    >
                      {texts.WEBCONTAINER_UPDATE_AVAILABLE}
                    </a>
                  ) : (
                    false
                  ),
                ]
                  .filter(i => !!i)
                  .reduce(
                    (acc, r, key) => acc.concat([r, <Fragment key={`r${key}`}>&nbsp;</Fragment>]),
                    [] as (JSX.Element | boolean)[]
                  )}
              </div>
            )}
            {userAuthorized && userExpired && (
              <div className={classes.offlineNotificationBox}>
                <BaseIcon data={mdiConnection} />
                <a href="/login">{texts.LOGIN_REQUESTED}</a>
              </div>
            )}
            <Routes
              modules={registeredModules}
              modulesLoaded={registeredModulesLoaded}
              routes={routes}
              userAuthorized={userAuthorized}
              modulePath={
                getConfigurationBoolValue(window.env.webcontainer, "LOAD_SERVER_MODULES")
                  ? getConfigurationStringValue(window.env.webcontainer, "SERVER_MODULES_PATH")
                  : undefined
              }
            />
            {((viewPortMobile && !openApp) || !viewPortMobile) && (
              <Box className={classes.copyrightBox}>
                <Typography variant="body2" color="textSecondary" align="center">
                  {"© "}
                  <Link color="inherit" href={texts.COPYRIGHT_LINK} target="_blank">
                    {texts.COPYRIGHT_TEXT}
                  </Link>
                  {` ${new Date().getFullYear()}`}
                </Typography>
              </Box>
            )}
          </main>
        </Router>
      )}
      <ErrorDetail />
      {mobilePlatform && (
        <>
          <CameraScanner />
          <NfcScanner />
          <NfcWriter />
        </>
      )}
      {platform === undefined && (
        <div className={classes.centeredContent}>
          <CircularProgress />
        </div>
      )}
      {!userAuthorized && networkStatus === NetworkStatus.offline && (
        <div className={classes.centeredContentOffline}>
          <TryLaterPage />
        </div>
      )}
      {infoModalIsOpen && <InfoModal />}
    </Box>
  );
};

export default App;
