import React, { useEffect, useMemo } from "react";

import { User } from "oidc-client";
import { connect, useSelector } from "react-redux";

import {
  AppBar,
  Avatar,
  Badge,
  Chip,
  Divider,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { useElementContext } from "@elx-element/common";
import { MessageTarget, NetworkStatus } from "@elx-element/common/enums";
import BaseIcon from "@elx-element/ui/DataDisplay/BaseIcon";
import { settingsButtonPlaceholderId } from "@elx-element/ui/Utils/SettingsButton";

import { mdiAccountOutline, mdiBellOutline, mdiEmailOutline, mdiMenu, mdiMonitor } from "@mdi/js";

import { AppState } from "../../store";
import * as CoreStoreAction from "../../store/core/action";
import { setCulture } from "../../store/main/action";
import {
  selectActiveModule,
  selectMachineSettings,
  selectMessages,
  selectNetworkStatus,
  selectNotifications,
  selectTexts,
} from "../../store/selectors";

import useStyles from "./styles";

import History from "../../history";
import Lang from "../../languages/lang";
import Theme from "../../theme";
import { handleStoreContentPosition } from "../app/core";
import MessagePopover from "../messagePopover";
import VersionPopover from "../versionPopover";

interface AppStateProps {
  menuOpen: boolean;
  cultures: Array<string>;
  culture: string;
  oidcUser?: User;
  texts: Lang;
}

interface AppDispatchProps {
  setMainMenuOpen: typeof CoreStoreAction.setMainMenuOpen;
  setCulture: typeof setCulture;
}

const Header = (props: AppStateProps & AppDispatchProps) => {
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = React.useState<HTMLDivElement | null>(null); // profil menu
  const [notificationsAnchorEl, setNotificationsAnchorEl] = React.useState<HTMLButtonElement | null>(null); // notifications
  const [messagesAnchorEl, setMessagesAnchorEl] = React.useState<HTMLButtonElement | null>(null); // messages
  const { classes, cx } = useStyles(); // css
  const openApp = useSelector(selectActiveModule); // reference na otevřený modul
  const messages = useSelector(selectMessages);
  const notifications = useSelector(selectNotifications);
  const isPrinting = useMediaQuery("print");
  const isTabletView = useMediaQuery(Theme.breakpoints.down("md")) && !isPrinting;
  const isMobileView = useMediaQuery(Theme.breakpoints.down("xs")) && !isPrinting;
  const isDesktopView = !isTabletView;
  const networkStatus = useSelector(selectNetworkStatus);
  const isOffline = networkStatus === NetworkStatus.offline;
  const { machineName } = useSelector(selectMachineSettings);
  const texts = useSelector(selectTexts);
  const { getParsedAuthToken } = useElementContext();

  const handleProfileMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setProfileMenuAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileMenuAnchorEl(null);
  };

  const handleMessagesClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (messages.length > 0) {
      setMessagesAnchorEl(event.currentTarget);
    }
  };

  const handleMessagesClose = () => {
    setMessagesAnchorEl(null);
  };

  const handleNotificationsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (notifications.length > 0) {
      setNotificationsAnchorEl(event.currentTarget);
    }
  };

  const handleNotificationsClose = () => {
    setNotificationsAnchorEl(null);
  };

  const handleMainMenuOpen = () => {
    props.setMainMenuOpen(true);
    handleStoreContentPosition(isDesktopView);
  };

  useEffect(() => {
    handleStoreContentPosition(isDesktopView);
    return () => {
      setMessagesAnchorEl(null);
      setNotificationsAnchorEl(null);
    };
  }, []);

  const name = useMemo(() => getParsedAuthToken()?.name ?? "", [props.oidcUser?.access_token ?? ""]);

  return (
    <AppBar position="absolute" className={cx(classes.appBar, props.menuOpen && classes.appBarOpen)} id="wC-header">
      <Toolbar className={cx(classes.toolbar, props.menuOpen && classes.toolbarOpen)}>
        {/* V mobilním zažízení zobrazujeme samostatný button pro vyvolání hlavního menu */}
        {isTabletView && !props.menuOpen && (
          <div className={classes.mainNavButtonContainer}>
            <IconButton onClick={handleMainMenuOpen} className={classes.mainNavButton}>
              <BaseIcon data={mdiMenu} />
            </IconButton>
          </div>
        )}

        {/* Zobrazení ikony aktivního modulu */}
        {isDesktopView && openApp?.tileIcon ? (
          <div className={classes.titleIcon}>
            <BaseIcon data={openApp?.tileIcon ?? ""} />
          </div>
        ) : (
          ""
        )}

        <Typography component="h1" color="secondary" noWrap className={classes.title}>
          {openApp?.localizedNames.find(x => x.culture === props.culture)?.text ?? props.texts.DASHBOARD}
        </Typography>
        <div id={settingsButtonPlaceholderId} className={classes.webcontainerSettingsButtonPlaceholder} />
        {props.cultures.length > 1 && (
          <FormControl fullWidth={false}>
            <Select
              variant={isMobileView ? "standard" : "outlined"}
              value={props.culture}
              size="medium"
              className={classes.languageSelectButton}
              onChange={e => props.setCulture(e.target.value as string)}
            >
              {props.cultures.map(x => (
                <MenuItem key={x} value={x}>
                  {x.substr(0, 2).toLocaleUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {!isOffline && (
          <IconButton className={classes.shakeEffect} onClick={handleNotificationsClick}>
            <Badge
              badgeContent={notifications.length > 0 ? notifications.length : undefined}
              classes={{ root: classes.badgeRoot }}
            >
              <BaseIcon data={mdiBellOutline} />
            </Badge>
          </IconButton>
        )}
        {!isOffline && (
          <IconButton className={classes.shakeEffect} onClick={handleMessagesClick}>
            <Badge
              badgeContent={messages.length > 0 ? messages.length : undefined}
              classes={{ root: classes.badgeRoot }}
            >
              <BaseIcon data={mdiEmailOutline} />
            </Badge>
          </IconButton>
        )}

        {isDesktopView && machineName && (
          <Chip
            icon={<BaseIcon data={mdiMonitor} className={classes.machineIcon} />}
            label={machineName}
            color="primary"
            className={classes.headerChip}
            title={texts.MACHINE_NAME}
          />
        )}
        {isDesktopView && (
          <Chip
            avatar={
              <Avatar>
                <BaseIcon data={mdiAccountOutline} fontSize="small" />
              </Avatar>
            }
            label={name}
            color="secondary"
            onClick={handleProfileMenuClick}
            className={classes.headerChip}
          />
        )}

        {isTabletView && (
          <Avatar
            color="primary"
            alt={name}
            title={name}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleProfileMenuClick}
            sx={{ width: 26, height: 26, fontSize: "small" }}
          >
            {name?.substring(0, 2).toUpperCase()}
          </Avatar>
        )}

        <Menu
          className={classes.popoverRoot}
          disableEnforceFocus
          disableAutoFocusItem
          disableRestoreFocus
          anchorEl={profileMenuAnchorEl}
          keepMounted
          open={Boolean(profileMenuAnchorEl)}
          onClose={handleProfileMenuClose}
        >
          {!isOffline && (
            <MenuItem
              onClick={() => {
                History.push("/user");
                handleProfileMenuClose();
              }}
            >
              {props.texts.PROFILE}
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              History.push("/logout");
              handleProfileMenuClose();
            }}
          >
            {props.texts.LOGOUT}
          </MenuItem>
          <VersionPopover />
        </Menu>
      </Toolbar>

      <MessagePopover
        open={Boolean(notificationsAnchorEl)}
        onClose={handleNotificationsClose}
        anchorEl={notificationsAnchorEl}
        type={MessageTarget.notification}
        data={notifications}
      />
      <MessagePopover
        open={Boolean(messagesAnchorEl)}
        onClose={handleMessagesClose}
        anchorEl={messagesAnchorEl}
        type={MessageTarget.message}
        data={messages}
      />

      <Divider />
    </AppBar>
  );
};

const mapStateToProps = (state: AppState): AppStateProps => ({
  menuOpen: state.core.mainMenuOpen,
  culture: state.main.culture,
  oidcUser: state.oidc.user,
  texts: state.main.texts,
  cultures: state.main.cultures,
});

const mapDispatchToProps = (dispatch: any): AppDispatchProps => ({
  setMainMenuOpen: (value: boolean) => dispatch(CoreStoreAction.setMainMenuOpen(value)),
  setCulture: (value: string) => dispatch(setCulture(value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
