import React, { useMemo } from "react";

import { connect, useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { useElementContext } from "@elx-element/common";
import { NetworkStatus, Notification } from "@elx-element/common/enums";
import { dispatchNotificationEvent } from "@elx-element/common/events/dispatchers";
import { NotificationEventModel } from "@elx-element/common/events/types";
import { RegisteredModule } from "@elx-element/common/types";
import { format } from "@elx-element/common/utils";
import BaseIcon from "@elx-element/ui/DataDisplay/BaseIcon";

import { mdiChevronLeft, mdiHelpCircleOutline, mdiMenu, mdiViewDashboardOutline } from "@mdi/js";

import { AppState } from "../../store";
import { setMainMenuOpen } from "../../store/core/action";
import { setInfoPopupOpen } from "../../store/main/action";
import { selectActiveModule, selectCulture, selectNetworkStatus, selectTexts } from "../../store/selectors";

import useNavigationStyles from "./styles";

import Lang from "../../languages/lang";
import { handleStoreContentPosition } from "../app/core";

interface AppStateProps {
  culture: string;
  menuOpen: boolean;
  texts: Lang;
  registeredModules: Array<RegisteredModule>;
}

const Navigation = (props: AppStateProps) => {
  const dispatch = useDispatch();
  const { classes, cx } = useNavigationStyles();
  const theme = useTheme();
  const texts = useSelector(selectTexts);
  const openApp = useSelector(selectActiveModule); // reference na otevřený modul
  const culture = useSelector(selectCulture);
  const isPrinting = useMediaQuery("print");
  const isTabletView = useMediaQuery(theme.breakpoints.down("md")) && !isPrinting;
  const isDesktopView = !isTabletView;
  const networkStatus = useSelector(selectNetworkStatus);
  const isOffline = networkStatus === NetworkStatus.offline;
  const { checkTokenAnyRoleExists } = useElementContext();

  const handleMainMenuOpen = () => {
    dispatch(setMainMenuOpen(true));
    handleStoreContentPosition(isDesktopView);
  };

  const handleMainMenuClose = () => {
    dispatch(setMainMenuOpen(false));
    handleStoreContentPosition(isDesktopView);
  };

  // Pokud se jedná o externí modul, k němuž chybí konfigurace, modul je blokován.
  const handleNavigationClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    m: RegisteredModule | undefined
  ) => {
    if (!isOffline) {
      if (isTabletView) {
        handleMainMenuClose();
      }
      if (m && m.licenseExpired()) {
        event.preventDefault();
      } else if (m && m.configExists === false && m.externalModule === true) {
        event.preventDefault();
        dispatchNotificationEvent(
          new NotificationEventModel(format(texts.CONFIG_FILE_MISSING, m.moduleId), Notification.warning)
        );
      }
    }
  };

  const handleInfoPopupOpen = () => {
    if (props.menuOpen) {
      dispatch(setMainMenuOpen(false));
    }
    dispatch(setInfoPopupOpen(true));
  };

  // Vrací data pro hlavní menu, seřazená podle property Order. Pokud řazení není definováno, řadíme nakonec (pozice 999).
  const getOrderedData = () => [...props.registeredModules].sort((a, b) => (a.order ?? 999) - (b.order ?? 999));
  const memoizedOrderedData = useMemo(() => getOrderedData(), [props.registeredModules]);

  return (
    <Drawer
      id="wC-navigation"
      variant={isTabletView ? "temporary" : "permanent"}
      anchor={isTabletView ? "top" : undefined}
      onClose={isTabletView ? handleMainMenuClose : undefined}
      classes={{
        paper: isTabletView
          ? cx(classes.drawerPaperMobilePlatform, !props.menuOpen && classes.drawerPaperCloseMobilePlatform)
          : cx(classes.drawerPaper, !props.menuOpen && classes.drawerPaperClose),
      }}
      open={props.menuOpen}
    >
      <div
        className={cx(
          classes.mainNavButtonContainer,
          props.menuOpen ? classes.mainNavButtonContainerFlexEnd : undefined
        )}
      >
        {/* Ikon buttony pro ovládání zobrazení hlavního menu */}
        {!props.menuOpen && (
          <IconButton onClick={() => handleMainMenuOpen()} className={classes.mainNavButton}>
            <BaseIcon data={mdiMenu} />
          </IconButton>
        )}
        {props.menuOpen && (
          <IconButton onClick={() => handleMainMenuClose()} className={classes.mainNavButton}>
            <BaseIcon data={mdiChevronLeft} />
          </IconButton>
        )}
      </div>

      <List classes={{ padding: classes.scrolledList }}>
        <NavLink
          to="/dashboard"
          onClick={event => handleNavigationClick(event, undefined)}
          className={cx(classes.navLink, !openApp?.route ? classes.navLinkActive : undefined)}
        >
          <Tooltip title={props.menuOpen ? "" : "Dashboard"} aria-label="dashboard" placement="right">
            <ListItem button>
              <ListItemIcon>
                <BaseIcon data={mdiViewDashboardOutline} />
              </ListItemIcon>
              <ListItemText primary="Dashboard" color="secondary" />
            </ListItem>
          </Tooltip>
        </NavLink>
        {memoizedOrderedData.length > 0 && (
          <>
            <Divider />
            {props.menuOpen && (
              <ListSubheader className={classes.menuListTitle} inset>
                {props.texts?.ACTIVE_MODULES}
              </ListSubheader>
            )}
            {memoizedOrderedData
              .filter(
                x =>
                  ((isTabletView && x.allowOnMobile) || isDesktopView) &&
                  (!x.requiredPermission || checkTokenAnyRoleExists(x.requiredPermission!))
              )
              .map(m => (
                <NavLink
                  key={m.moduleId}
                  to={`/${m.route ?? m.moduleId}`}
                  onClick={event => handleNavigationClick(event, m)}
                  className={cx(
                    classes.navLink,
                    openApp?.route === m.route ? classes.navLinkActive : undefined,
                    isOffline || m.licenseExpired() ? classes.notAllowed : undefined
                  )}
                >
                  <Tooltip
                    title={props.menuOpen ? "" : m.getLocalizedName(culture)}
                    aria-label={m.tileIcon}
                    placement="right"
                    classes={{ tooltip: classes.tooltipPopper }}
                  >
                    <ListItem button>
                      <ListItemIcon>
                        <BaseIcon data={m.tileIcon ?? ""} />
                      </ListItemIcon>
                      <ListItemText primary={m.getLocalizedName(props.culture)} />
                    </ListItem>
                  </Tooltip>
                </NavLink>
              ))}
          </>
        )}
      </List>
      <Divider />

      {window.env.webcontainer.SHOW_TENANT_CONTENT && (
        <Tooltip title={texts.ABOUT_US}>
          <IconButton aria-label="infoBtn" className={classes.infoBtn} onClick={handleInfoPopupOpen}>
            <BaseIcon data={mdiHelpCircleOutline} />
            <Typography variant="body1" marginLeft={theme.spacing(2)}>
              {texts.ABOUT_US}
            </Typography>
          </IconButton>
        </Tooltip>
      )}
    </Drawer>
  );
};
const mapStateToProps = (state: AppState): AppStateProps => ({
  culture: state.main.culture,
  menuOpen: state.core.mainMenuOpen,
  texts: state.main.texts,
  registeredModules: state.core.registeredModules,
});
export default connect(mapStateToProps)(Navigation);
