import { useSelector } from "react-redux";

import { Box, Link, Typography } from "@mui/material";

import { selectTexts } from "../../store/selectors";

import useStyles from "./styles";

const Contacts = () => {
  const { classes } = useStyles();
  const texts = useSelector(selectTexts);
  return (
    <Box className={classes.content}>
      <Typography variant="h2">{texts.CONTACTS}</Typography>
      <Box className={classes.halfCol}>
        <b>E LINKX a. s.</b>
        <br />
        Novoveská 1262/95
        <br />
        709 00 Ostrava - Mariánské Hory
      </Box>
      <Box className={classes.halfCol}>
        <b>{texts.BRANCH} Praha</b>
        <br />
        Na Strži 1702/65
        <br />
        140 00 Praha 4
      </Box>
      <hr className={classes.hr} />
      <Link href="https://www.elinkx.cz" target="_blank">
        www.elinkx.cz
      </Link>
      <br />
      <br />
      <Typography variant="body1">
        IČ: 25847180,
        <br /> DIČ: CZ25847180
      </Typography>
      <Box className={classes.halfCol}>
        <Typography variant="body1">
          <b>{texts.INFORMATION}</b>:<br /> <Link href="mailto:info@elinkx.cz">info@elinkx.cz</Link>
        </Typography>
      </Box>
      <Box className={classes.halfCol}>
        <Typography variant="body1">
          <b>{texts.BUSINESS_DEPARTMENT}</b>:<br /> <Link href="mailto:obchod@elinkx.cz">obchod@elinkx.cz</Link>
        </Typography>
      </Box>
      <hr className={classes.hr} />
    </Box>
  );
};

export default Contacts;
